<mat-card (click)="addQueryParam(organization.name)" class="mat-elevation-z3 pointer">
  <mat-card-content fxLayoutAlign="center" style="margin: 1rem;">
      <img mat-card-image *ngIf="organization.image_display_url" [src]="organization.image_display_url" class="organizationImage">
      <div *ngIf="!organization.image_display_url" class="organizationImage">
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="column" style="place-content: stretch flex-start; align-items: flex-start;">
      <mat-label class="mat-body-2">{{organization.display_name }}</mat-label>
      <mat-label class="mat-body-1">{{organization.package_count}} {{'DATA.DATASETS' | translate}}</mat-label>
    </mat-card-actions>
</mat-card>