<div class="dataset-detail-min-height center-flex" style="min-height: 42em;">
  <mat-tab-group fxFlex="58" fxFlex.xs="90" fxFlex.sm="90" dynamicHeight>
    <mat-tab  label="My wishlist">
      <div class="request-table">
        <h2>
          Overview of wishlist items
       </h2>
       </div>
      <ng-container class="empty-wishlist" *ngIf="isWishListEmpty() else WISHLIST">
        <p>Your wishlist is empty. Navigate to <a [routerLink]="'/data'">Data</a> tab to select datasets to add into your wishlist.</p>
      </ng-container>
      <ng-template #WISHLIST>
      </ng-template>

      <!-- if not empty, show list of dataset cards -->
      <mat-card *ngFor="let item of wishlist" class="mat-elevation-z2" fxLayout="column" style="width: 98%; margin: 1%;">
        <mat-grid-list cols="3" rowHeight="11em" gutterSize="0em">
          <mat-grid-tile colspan="2">
            <div style="display: block; width: 100%; margin: 1em;">
              <div style="font-size: 20px;"><a [routerLink]="item.routerLink"><b>{{ item.title }}</b></a></div>
              <div><b>Data owner:</b> {{ item.owner }}</div>
              <div><b>Updated:</b> {{ item.updated | localPipe :'dd MMMM yyyy' | translate }}</div>
              <div><b>Theme:</b></div>
              <!-- Add more div elements for additional items -->
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <div style="width: 100%; text-align: center;">
              <div>
                <ng-container *ngIf="isAuthorized() else UNAUTHORIZED_REQUEST">
                  <button style="width: 60%" mat-raised-button class="request" [routerLink]="item.routerLink" [queryParams]="{tabName:'contact_tab'}">Request access</button>
                </ng-container>
              </div>
              <br>
              <div><button style="width: 60%" mat-raised-button class="dismiss" (click)="dismissItem(item.id)">Remove from wishlist</button></div>
              <!-- Add more div elements for additional items -->
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card>
    </mat-tab>
      
    <mat-tab label="Requests sent">
    <!-- <mat-tab label= "{{ '<text_here' | translate }}"> -->
    <ng-container *ngIf="isAuthorized() else UNAUTHORIZED_ACCESS">
      <div class="request-table">
        <h2>
           Overview of requests sent
        </h2>
        <div class="request-title">
           <b>Status</b>
           <b>Dataset</b>
           <b>Owner</b>
        </div>
        <br>
        <!-- <div *ngIf="userSubscriptions.length == 0">
           <p>You have no requests sent. Choose from your wishlist a dataset to request access.</p>
        </div> -->
        <div class="request-content">
          <mat-card class="app-background" expanded="false">
                <mat-label class="color-primary request-label">
                    <div style="color:orange;" >Pending</div>
                    <div>Clinical data research</div>
                    <div>OpenClinica</div>
                </mat-label>
          </mat-card>
        </div>
     </div>
    </ng-container>
    </mat-tab>

    <mat-tab label="Requests received">
    <!-- <mat-tab label= "{{ '<text_here' | translate }}"> -->
      <ng-container *ngIf="isAuthorized() else UNAUTHORIZED_ACCESS">
        <div class="request-table">
          <h2>
             Overview of requests received
          </h2>
          <div class="request-received-title">
             <b>Dataset</b>
             <b>Requested by</b>
             <b>Action</b>
          </div>
          <br>
          <!-- <div *ngIf="userSubscriptions.length == 0">
             <p>You have no requests sent. Choose from your wishlist a dataset to request access.</p>
          </div> -->
          <div class="request-content">
            <mat-card class="app-background" expanded="false">
                  <mat-label class="color-primary request-received-label">
                    <div>Biosample data</div>
                    <div>Gemeente Utrecht</div>
                    <div>
                      <button mat-raised-button color="primary">APPROVE</button>
                      <button mat-raised-button color="warn">REJECT</button>
                    </div>
                  </mat-label>
            </mat-card>
          </div>
       </div>
      </ng-container>

    </mat-tab>

    <mat-tab label="Messages">
    <ng-container *ngIf="isAuthorized() else UNAUTHORIZED_ACCESS">
      <p>Chat box discussing requests and access rights</p>
    </ng-container>
  </mat-tab>
  </mat-tab-group>
</div>

<ng-template #UNAUTHORIZED_ACCESS>
  <p style="color:red">You need to login to access the content of this tab</p>
  <button mat-raised-button color="warn" class="user" (click)="userLogin()">{{'HEADER.LOGIN' | translate}}</button>
</ng-template>

<ng-template #UNAUTHORIZED_REQUEST>
  <button style="width: 60%" mat-raised-button color="warn" class="user" (click)="userLogin()">Login to request access</button>
</ng-template>
