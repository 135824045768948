<div [ngSwitch]="customerName">

<div *ngSwitchCase="'Malmo'" class="customColorThemeGroups">
  <mat-card (click)="addQueryParam(group.name)" class="mat-elevation-z0"
            [ngClass]="'background-'+index" (mouseout)="mouseOver=false"
    (mouseover)="mouseOver=true" style="padding: none; margin: 5px">
    <mat-card-header>   
      <mat-card-title style="height: 60px; word-break: normal; font-size: 14px">{{group.translatedName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center" fxLayoutGap="10px">
      <img class="img_invert" [src]="group.image_display_url" height="50px" [alt]="group.title" *ngIf="hasDisplayImage(group)">
      <img class="img_invert" *ngIf="!hasDisplayImage(group)">
    </mat-card-content>
    <mat-card-footer class="px1">   
      <mat-label fxLayoutAlign="end" style="font-size: 14px; color: white; margin-right: 10px;">{{group.package_count}}</mat-label>
    </mat-card-footer>
  </mat-card>
</div>        

<div *ngSwitchDefault class="customColorThemeGroups">
  <mat-card (click)="addQueryParam(group.name)" class="mat-elevation-z0"
            [ngClass]="'background-'+index" (mouseout)="mouseOver=false"
    (mouseover)="mouseOver=true">
    <mat-card-header>
      <mat-card-title style="height: 40px; font-size: 14px">{{group.translatedName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center" fxLayoutGap="10px">
      <img class="img_invert" [src]="group.image_display_url" [alt]="group.title" *ngIf="hasDisplayImage(group)">
      <div class="img_invert" *ngIf="!hasDisplayImage(group)"> </div>
    </mat-card-content>
    <mat-card-footer class="px1">
      <mat-label fxLayoutAlign="end" style="font-size: 14px; color: white; margin-right: 10px;">{{group.package_count}}</mat-label>
    </mat-card-footer>
  </mat-card>
</div>

</div>