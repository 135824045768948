<div fxFlex fxLayout="row">
  <mat-label>{{ "DATA.RATING" | translate }}</mat-label>
  <div *ngFor="let star of [].constructor(getMaxRating()); let i = index">
    <button mat-icon-button (mouseover)="mouseOver(i)" (mouseout)="mouseOut()" (click)="submitRating(i)" class="{{getClass(i)}}">
      <mat-icon *ngIf="isStarSelected(i); else empty">star</mat-icon>
      <ng-template #empty>
        <mat-icon>star_border</mat-icon>
      </ng-template>
    </button>
  </div>
  <div [ngSwitch]="getRatingsCount()">
    <div *ngSwitchCase="getRatingsCount() === 1">
      ({{ getRatingsCount() }} {{"DATA.VOTE" | translate}})
    </div>
    <div *ngSwitchDefault>
      ({{ getRatingsCount() }} {{"DATA.VOTES" | translate}})
    </div>
  </div>
</div>
