      <div fxLayout="column" fxFlex=60>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Nieuwegein Open Data</h1>
        <p style="text-align: justify;">
          Welkom op het open data platform van de gemeente Nieuwegein! Op deze website vindt u een overzicht van de open
          data die
          wij beschikbaar hebben gesteld. Als u ideeën heeft, verbeterpunten ziet of vragen wilt stellen, neem dan
          contact met ons op.
          In de toekomst gaan wij meer datasets publiceren. Wij werken samen met andere overheden om meer open data op
          een makkelijke
          manier beschikbaar te stellen. Samen werken we ook aan standaardisatie van datasets.
        </p>
        <h2>Waardevolle informatie</h2>
        <p style="text-align: justify;">
          Om haar werk zo goed mogelijk te kunnen doen, verzamelen wij veel verschillende soorten gegevens. Informatie
          die waardevol
          en interessant kan zijn voor bedrijven, organisaties, journalisten, onderzoekers en bewoners. Zoals
          plattegronden met informatie,
          geografische data, resultaten van bewonersonderzoeken of allerlei gegevens over de stad. Volgens onderzoek kan
          open data grote
          economische waarde hebben. Veel mensen en organisaties kunnen ervan profiteren. Om deze ontwikkelingen
          mogelijk te maken,
          stellen wij deze data zo veel en zo gemakkelijk mogelijk beschikbaar. De data zijn bekostigd uit publieke
          middelen en daarom
          gratis toegankelijk.Voorbeelden van kaarten die met open data gemaakt zijn kunt u vinden op de
          <a href="https://www.nieuwegein.nl/inwoner/in-en-om-nieuwegein/geoappstore/">Nieuwegein GeoAppStore</a>.
        </p>
        <h2>Gebruiksvoorwaarden open data Gemeente Nieuwegein</h2>
        <p style="text-align: justify;">
          Iedereen – particulier en bedrijf – mag de als zodanig aangemerkte open data, zoals geplaatst op
          nieuwegein.dataplatform.nl hergebruiken
          in eigen toepassingen.<br>
          <br>
          Wij stellen de inhoud van de datasets met de grootst mogelijke zorg samen. Dit is echter geen garantie dat
          deze datasets
          volledig, accuraat en juist zijn. De gemeente Nieuwegein sluit iedere aansprakelijkheid uit voor welke schade
          dan ook,
          direct en/of indirect, op enige wijze ontstaan door en/of voortvloeiend uit elk gebruik van deze datasets,
          waaronder ook –
          maar niet alleen – handelingen van een gebruiker van de datasets die op welke manier dan ook zijn ingegeven
          door de hier geplaatste informatie.
        </p>
        <p></p>
        <p></p>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <h2 class="mat-headline ">Privacy</h2>
          <p style="text-align: justify;">
            Uiteraard moet u er op kunnen vertrouwen dat de overheid uw gegevens beschermt. Informatie waarbij uw
            privacy niet is gegarandeerd, wordt daarom
            niet gepubliceerd. Datasets worden, waar nodig, geanonimiseerd.<br>
          </p>
          <h2 class="mat-headline ">Proclaimer</h2>
          <p style="text-align: justify;">
            De gemeente Nieuwegein is verantwoordelijk voor de inhoud van deze website en datasets, en doet er alles aan
            deze actueel en juist te houden.
            Komt u desondanks toch iets tegen dat niet correct is of verouderd, dan stellen wij <a
              href="mailto:opendata@nieuwegein.nl">uw reactie</a>
            bijzonder op prijs.<br>
          </p>
          <br>
          <a class="twitter-timeline" data-lang="nl" data-width="350" data-height="500" data-theme="light"
            href="https://twitter.com/Gem_Nieuwegein?ref_src=twsrc%5Etfw">Tweets by Gem_Nieuwegein</a>
        </div>
      </div>