import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppHeaderComponent} from './app-header/app-header.component';
import {SharedModule} from '@shared/shared.module';
import {AppFooterComponent} from './app-footer/app-footer.component';
import {AppRoutingModule} from './app-routing.module';
import { HomeComponent } from 'app/home/home.component';
import {DatasetsService} from '@shared/services/datasets.service';
import {DatasetResourcesService} from '@shared/services/dataset-resources.service';
import {NotificationService} from '@shared/services/notification.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HeaderButtonComponent } from './app-header/header-button/header-button.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from '@shared/functions/app-init';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { CustomSharedModule } from '../../../portal-config/custom.module';
import { DatasetDetailsResolver } from '@shared/resolvers/dataset-details.resolver';
import { SwiperModule } from 'swiper/angular';
import { CarouselComponent } from 'app/home/components/carousel/carousel.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CustomSharedModule,
    SwiperModule
  ],
  declarations: [
    HeaderButtonComponent,
    AppHeaderComponent,
    AppFooterComponent,
    PrivacyPolicyComponent,
    HomeComponent,
    CarouselComponent
    ],
  exports: [
    AppHeaderComponent,
    HeaderButtonComponent,
    AppFooterComponent,
    AppRoutingModule,
    KeycloakAngularModule,
    CarouselComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps:[KeycloakService,DynamicInjectorService]
    },
    DatasetsService,
    DatasetDetailsResolver,
    DatasetResourcesService,
    NotificationService]
})
export class CoreModule {
}
