  <div fxLayout="column">
    <p style="text-align: justify;">
      Welkom bij het Datapakhuis van de gemeente Zaanstad. In het datapakhuis vindt u alle datasets van de gemeente
      Zaanstad in twee vormen: open en gesloten. De open datasets kunt u direct downloaden. De gesloten datasets kunt u
      aanvragen, waarna u binnen 5 werkdagen antwoord krijgt of u geautoriseerd wordt om de dataset te mogen ontvangen.
      Wilt u een datasets aanvragen die niet in de catalogus van ons Datapakhuis te vinden is, dan kunt u die aanvragen
      bij
      ‘vraag nieuwe dataset aan’. Als u verbeterpunten heeft of vragen wilt stellen neem dan <a
        href="mailto:data@zaanstad.nl">contact</a> met ons op.
    </p>
    <app-themes></app-themes>
  </div>
