import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrganizationConfig } from "@shared/models/organization.config.model";
import { ACC, Environment, PRD, TST } from "@shared/models/environment.model";
import { environment } from "environments/common/environment.default";
import { settingsObject } from "../../../../portal-config/settings";
import { firstValueFrom } from 'rxjs';
import { SettingsConfig } from "@shared/models/settings.config.model";
import { CanActivate, Router } from "@angular/router";

/**
 * Injects flexible information at the start.
 * Changing the organization name and id as well as the environment base on the url it is currently on.
 * If this runs locally it will not be able to determine what organization and environment it is supposed to be and will put out a default 'sandbox' and 'tst' configuration.
 * If you want to test another organization or environment locally be sure to use one of the local scripts.
 * @todo: replace router with navigation service once it is implemented.
 */
@Injectable({
    providedIn: 'root'
})
export class DynamicInjectorService implements CanActivate {

    private organization:OrganizationConfig;
    private settings:SettingsConfig;
    private environment:Environment;
    private static readonly PORTAL_CONFIG_PATH: string = '../../../../portal-config';
    private static readonly CORE_ASSETS_PATH: string = '/assets';
    private startedAlernativeStartRoute:boolean = false; //used to start the portal on a different page. Home should still be accessible after.

    //TODO: replace router with navigation service once it is implemented.
    constructor(private http: HttpClient, private router:Router){
        this.environment = environment;
    }

    //Adds the option to start the page on a different route
    public canActivate(): boolean{
        if(this.organization.alternativeStartRoute && !this.startedAlernativeStartRoute){
            this.startedAlernativeStartRoute = true;
            this.router.navigate([this.organization.alternativeStartRoute]);
        } else {
            return true;
        }
    }

    public initEnvironment(url: string):Promise<void>{
        return new Promise<void>((resolve, reject) => {
            this.settings = settingsObject;
            this.loadOrganization(url).then(response => this.organization = new OrganizationConfig(response))
            .then(() => {
                this.adjustEnvironmentBasedOnConfigs();
                resolve();
            })
            .catch(error => reject(error));
        })
          
    }

    private determineOrganizationByUrl(url:string):string{
        var placeholderURL = new URL(url).hostname;
        const splitUrl: string[] = placeholderURL.split(".");

        var foundFilter:boolean = false;
        this.settings.urlFilters.forEach(value => {
            if(url.includes(value)){
                foundFilter = true;
            }
        })
        if(this.settings.uniqueCustomerReferences.has(placeholderURL)){
            //found a unique url
            console.debug(`found a unique customer reference by the url ${placeholderURL} which translates to ${this.settings.uniqueCustomerReferences.get(placeholderURL)}`)
            return this.settings.uniqueCustomerReferences.get(placeholderURL);
        } else if(!foundFilter){
            //for local testing for example.
            console.warn(`Did not find the organization in the url: ${placeholderURL}. Switching to the local organization: ${this.environment.organization}`);
            return this.environment.organization;
        } else if (splitUrl[0] == 'tst' || splitUrl[0] == 'acc' || splitUrl[0] == 'dataplatform'){
            return 'dataplatform'; 
        } else {
            
            if(placeholderURL.startsWith('tst-') || placeholderURL.startsWith('acc-')){
                placeholderURL = placeholderURL.substring(4); 
            }
            return (placeholderURL.split(".")[0]); 
        }
    }

    private determineEnvironmentByUrl(url:string):string {
        var foundFilter:boolean = false;
        this.settings.urlFilters.forEach(value => {
            if(url.includes(value)){
                foundFilter = true;
            }
        })

        if(!foundFilter){
            console.warn(`Did not find environment in the url: ${new URL(url).hostname}. Switching to the local environment: ${this.environment.environment}`);
            return this.environment.environment;
        } else {
            const placeholderUrl = new URL(url).hostname;

            if(placeholderUrl.startsWith('tst-') || placeholderUrl.split('.')[0] == 'tst'){
                return TST;
            } else if (placeholderUrl.startsWith('acc-') || placeholderUrl.split('.')[0] == 'acc'){
                return ACC;
            } else {
                return PRD;
            }
        }
    }

    private loadOrganization(url : string): Promise<OrganizationConfig>{
        this.environment.organization = this.determineOrganizationByUrl(url);
        this.environment.environment = this.determineEnvironmentByUrl(url);
        //The only exception to use httpClient over API service, since API service is dependant on this.
        return firstValueFrom(this.http.get<OrganizationConfig>(`${DynamicInjectorService.PORTAL_CONFIG_PATH}/organizations/${this.environment.organization}/customer.json`, { headers: { 'skip': 'true'}}))
    }

    private adjustEnvironmentBasedOnConfigs():void{
        this.environment.production = this.environment.environment == PRD;
        this.environment.name = this.organization.name;
        this.environment.apiUrl = this.organization.apiUrl[this.environment.environment];
        this.environment.supportApi = this.settings.supportUrl[this.environment.environment];
        this.environment.matomoSiteId = this.organization.matomoSiteId[this.environment.environment];
        this.environment.matomoUrl = this.settings.matomoUrl[this.environment.environment];
        this.environment.organizationId = this.organization.organizationId[this.environment.environment];
        this.environment.auth = this.getOrganizationConfig().auth;
    }

    public getEnvironment():Environment{
        return this.environment;
    }

    public getOrganizationConfig():OrganizationConfig{
        return this.organization;
    }

    public getSettingsConfig():SettingsConfig{
        return this.settings;
    }

    public setFavIcon():void {
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.getFaviconHref()
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    public getTheme():string {
        return `${this.environment.organization}-theme`;
    }

    public getCustomerConfigPath(): string {
        return `${DynamicInjectorService.PORTAL_CONFIG_PATH}/organizations/${this.environment.organization}`
    }
    
    private getFaviconHref(){
        let faviconHref = `${DynamicInjectorService.CORE_ASSETS_PATH}/default_favicon.ico`
        if (this.organization.assets.favicon != undefined){
            faviconHref = `${this.getCustomerConfigPath()}/assets/${this.organization.assets.favicon.path}`
        }
        return faviconHref
    }
}

export class CombinedConfigurations {
    organization:OrganizationConfig;
    settings:SettingsConfig;
    environment:Environment;

    constructor(organization:OrganizationConfig, settings:SettingsConfig, environment:Environment){
        this.organization = organization;
        this.settings = settings;
        this.environment = environment;
    }
}
