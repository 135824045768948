      <div  fxLayout="column" fxFlex=60>
          <h1 class="mat-headline">Open data Stichtse Vecht</h1>
          <p style="text-align: justify;">
            Op deze website vindt u open data van de gemeente Stichtse Vecht, zoals verkiezingsuitslagen en de leeftijdsopbouw. 
            Open data zijn openbare gegevens die door iedereen vrij te gebruiken zijn. 
            U kunt de gegevens inzien uit interesse, gebruiken voor uw onderzoek of voor het ontwikkelen van apps. 
          </p>
          <br>
          <h2 class="mat-subheading-1">Wat is open data?</h2>
          <p style="text-align: justify;">
            Open data zijn gegevens die beschikbaar zijn voor iedereen. 
            Deze gegevens bevatten geen persoonlijke of vertrouwelijke informatie. 
            U kunt de datasets gratis downloaden en gebruiken naar eigen inzicht. De datasets hebben geen auteursrechten of rechten van derden.
          </p>
          <br>
          <h2 class="mat-subheading-1">Wat voor data?</h2>
          <p style="text-align: justify;">
            Diverse datasets over de volgende onderwerpen zijn terug te vinden op deze website:
          </p>
          <ul>
            <li><p>Verkiezingen en stemlokalen</p></li>
            <li><p>Adressen en gebouwen</p></li>
            <li><p>Cijfers over de inwoners </p></li>
            <li><p>Openbare voorzieningen</p></li>
          </ul>
          <p style="text-align: justify;">
            De open datasets die hier te vinden zijn bevatten alleen data van de gemeente Stichtse Vecht.
          </p>
          <p style="text-align: justify;">
            Als gemeente proberen we zoveel mogelijk open data aan te bieden. 
            Het aantal datasets op deze website groeit daarom ook met de jaren. 
            Momenteel bieden we meer dan 50 datasets aan. 
             <a href="/#/data" target="_blank"><u>Ga nu naar de data.</u></a>
          </p>
          <br>
          <h2 class="mat-subheading-1">Verantwoordelijkheid</h2>
          <p style="text-align: justify;">
            De gemeente Stichtse Vecht is niet verantwoordelijk voor schade die is ontstaan vanuit direct of indirect gebruik van de aangeboden open datasets.
          </p>
          <br>
          <h2 class="mat-subheading-1">Contact</h2>
          <p style="text-align: justify;">
            De datasets zijn met zorg opgebouwd en we werken ze geregeld bij. 
            Is een dataset niet zoals u verwacht, staat er een foutje in of mist u iets? We horen het graag. 
            <a href="mailto:data@stichtsevecht.nl" target="_blank"><u>Laat het ons snel weten.</u></a>
          </p>
    </div>
    <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
    <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <div class="policy-custom">
          <h2 class="mat-subheading-1">Open data en privacy</h2>
          <p style="text-align: justify;">De gemeente Stichtse Vecht gaat zorgvuldig om met de gegevens van haar
            inwoners en houdt zich aan de Wet bescherming persoonsgegevens. Bij open data gaat het alleen om informatie die openbaar
            gemaakt mag worden, dus geen persoonlijke, vertrouwelijke of bedrijfsgevoelige gegevens. Dus geen gegevens over personen.
            Informatie die schade kan veroorzaken of de veiligheid in gevaar kan brengen wordt ook niet via open data verspreid.
          </p>
        </div>
    </div>