<div class="footer" fxLayout fxLayoutAlign="center">
   <div fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=80>
     <br>
     <div class="footer-content" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.xs="column">
       <!-- FIRST COLUMN BRAND -->
       <div fxLayout="column"  fxFlex="50" fxLayoutAlign="start">
           <div>               
               <img src='../../../../portal-config/organizations/greeninnovationhub/assets/images/footer_logo_banner.png' style="width: 80%; height: auto; border-radius: 10px;">
            </div>
       </div>
       <!-- SECOND COLUMN CONTACT -->
       <div fxLayout="column"  fxFlex="25" fxLayoutAlign="start">
      </div>
      <!-- THIRD COLUMN SOCIAL -->
      <div fxLayout="column"  fxFlex="25" fxLayoutAlign="start">
      </div>
    </div>
  </div>
</div>
