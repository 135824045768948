<div class="home-text">
  <div class="banner">
    <div class="banner-text">
      <h1 class="title" style="font-size: 42px; color:white;"><strong>{{ 'CUSTOMER.HEALTH-RI.BANNER_TITLE' | translate}}</strong></h1>
      <h2 style="font-size: 23px; color:white">
        {{ 'CUSTOMER.HEALTH-RI.BANNER_TEXT' | translate}}
      </h2>
    </div>
    <div class="banner-search">
      <app-search-bar></app-search-bar>
      <h2 class="dataset-counter">{{ 'CUSTOMER.HEALTH-RI.SEARCH_TEXT_1' | translate}} {{totalNoOfDatasets}} {{ 'CUSTOMER.HEALTH-RI.SEARCH_TEXT_2' | translate}} </h2>
    </div>
    <br>
  </div>
  <div class="notification-element">
    <h2 class="intro-text"> 
      <p class="intro-title">{{ 'CUSTOMER.HEALTH-RI.INTRO_TITLE' | translate}}</p>
      <p>{{ 'CUSTOMER.HEALTH-RI.INTRO_TEXT' | translate}}</p>
    </h2>
    <img class="notification-icon" [src]="getAsset('images/notification_icon.png')">
    <h2 class="notification-text">{{ 'CUSTOMER.HEALTH-RI.NOTIFICATION_TEXT' | translate}} </h2>
  </div>
  <!-- <app-themes></app-themes> -->
</div>


