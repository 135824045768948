<h1 mat-dialog-title>{{ 'MAP_TAB.UPLOAD_COHORT_TITLE' | translate}}</h1>
<mat-grid-list cols="10" rowHeight="1.7em">
  <mat-grid-tile colspan="2">
    <button mat-flat-button color="primary" class="auth-upload-btn" title="{{'MAP_TAB.SELECT_PUBLIC_KEY' | translate}}"
        (click)="publicKeyFileUpload.click()">{{ 'MAP_TAB.PUBLIC_KEY' | translate}}
  </button>
    <input #publicKeyFileUpload type="file" accept=".asc" (change)="onPublicKeyFileSelected($event)" style="display:none"/>
  </mat-grid-tile>
  <mat-grid-tile colspan="4">
    <div class="mat-grid-tile-label auth-upload-status">
    {{publicKeyFileName || 'MAP_TAB.UPLOAD_EMPTY' | translate}}
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="4">
  </mat-grid-tile>
  <mat-grid-tile colspan="10">
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <button mat-flat-button color="primary" class="auth-upload-btn" title="{{'MAP_TAB.SELECT_COHORT_KEY' | translate}}"
        (click)="cohortFileUpload.click()">{{ 'MAP_TAB.COHORT_KEY' | translate}}
  </button>
    <input #cohortFileUpload type="file" accept=".csv.gpg" (change)="onCohortFileSelected($event)" style="display:none"/>
  </mat-grid-tile>
  <mat-grid-tile colspan="4">
    <div class="mat-grid-tile-label auth-upload-status">
    {{cohortFileName || 'MAP_TAB.UPLOAD_EMPTY' | translate}}
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="3">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="coordinates">
      <mat-button-toggle value="coordinates">{{ 'MAP_TAB.COORDINATES' | translate}}</mat-button-toggle>
      <mat-button-toggle value="addresses">{{ 'MAP_TAB.ADDRESSES' | translate}}</mat-button-toggle>
  </mat-button-toggle-group>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <button mat-mini-fab color="primary" class="auth-dialog-icon" aria-label="Example icon button with a plus one icon">
      <mat-icon>help</mat-icon>
    </button>
  </mat-grid-tile>
</mat-grid-list>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" [disabled]="! (publicKeyFile && cohortFile)" (click)="onSubmitClick()" title="{{ 'MAP_TAB.SUBMIT_HELP_TEXT' | translate}}" [mat-dialog-close]="data">OK</button>
  <button mat-flat-button color="primary" (click)="onCancelClick()">Cancel</button>
</div>
