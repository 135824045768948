import { Environment } from "@shared/models/environment.model";

const json = require('../../../package.json');


/**
 * @access should be accessed through the dynamic.injector.service.ts getEnvironment()
 * @deprecated 19-04-2022 Not really deprecated, but there for the warning if its used in places where it shouldn't be. Which is everywhere but the dynamic injector and some build scripts.
 */
export const environment : Environment = {
  production: true,
  environment: 'prd',
  organization: 'sandbox',
  name: 'Sandbox',
  apiUrl: 'https://tst-ckan-dataplatform-sandbox.dataplatform.nl/api/',
  supportApi: 'https://tst-mail.dataplatform.nl/external-email',
  matomoUrl: 'https://tst-matomo.dataplatform.nl/',
  matomoSiteId: 0,
  organizationId: '*',
  version: json.version
};
