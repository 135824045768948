<div fxLayoutAlign="center">
  <mat-spinner *ngIf="showLoading"></mat-spinner>
</div>
<div class="scrolling-wrapper" *ngIf="!showLoading" fxLayout fxLayout.xs="row" fxLayoutGap.xs="20px">
    <div fxLayout="row" fxFlex="60" fxFlex.sm="90" fxFlex.xs="90" fxLayout.xs="column" fxLayoutGap="10px grid" *ngFor="let group of groups;  let i=index">
        <!-- <div fxLayout="column" fxFlex="auto" *ngFor="let group of groups;  let i=index"> -->
      <div *ngIf="i < 20">
        <div class="customColorThemeGroups">
          <mat-card (click)="addQueryParam(group.name)" class="mat-elevation-z0" [ngClass]="'background-'+i"
            (mouseout)="mouseOver=false" (mouseover)="mouseOver=true" style=" border-radius: 10px;">
            <mat-card-content fxLayoutAlign="center" fxLayoutGap="10px">
              <div>
                <img class="img_invert" [src]="group.image_display_url" [alt]="group.title"
                  style=" max-width:100%; height: auto; max-height: none; ">
              </div>
            </mat-card-content>
            <mat-card-footer class="px1">
            </mat-card-footer>
          </mat-card>
          <div class="title">{{group.display_name}}</div>
      </div>
    </div>
  </div>