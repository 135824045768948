<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
        <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
            <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61"
                fxLayoutAlign="space-between">

                <div fxFlex="20">
                    <h2 class="mat-headline" style="color:#00a0ba"><strong>Contact</strong></h2>
                    <p class="footer-text-color" style="color:#00a0ba; font-family: open sans;">
                        <a style="color:#00a0ba; font-family: open sans;" href="https://www.almere.nl/contact">
                            www.almere.nl/contact</a><br>
                    </p>

                </div>
                <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
                    <div fxFlex="20">
                        <h2 class="mat-headline" style="color:#00a0ba"><strong>Adres</strong></h2>
                        <p class="footer-text-color" style="color:#00a0ba; font-family: open sans;">
                            Bezoekadres: Stadhuisplein 1, 1315 HR Almere<br>
                            Postadres: Postbus 200, 1300 AE Almere<br>
                        </p>

                    </div>
                </div>
                <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>