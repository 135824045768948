import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';


@Component({
  selector: 'app-provincie-noord-holland-footer-text',
  templateUrl: './provincie-noord-holland-footer-text.component.html',
  styleUrls: ['./provincie-noord-holland-footer-text.component.scss']
})
export class ProvincieNoordHollandFooterTextComponent implements OnInit, ConfigInterface {


  constructor() {

  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

}
