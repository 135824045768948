import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationStart, NavigationExtras, NavigationStart, Router } from "@angular/router";
import { BreadcrumbModel } from "@shared/models/breadcrumb.model";
import { NavigationResponse } from "@shared/models/navigation.response.model";
import { Observable, BehaviorSubject, filter } from "rxjs";

/**
 * All routing should be done via this service.  
 * If you find routing being done via another route (often found by seeing @angular/router being implemented somewhere else) it is best practise to move it to this service instead.
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    /**
     * a stream to listen to routes that are passing
     */
    private navigationSubject: BehaviorSubject<NavigationResponse> = new BehaviorSubject<NavigationResponse>(new NavigationResponse('/'));

    /**
     * a stream to listen to snapshots that are passing
     */
    private snapshotSubject: BehaviorSubject<ActivatedRouteSnapshot> = new BehaviorSubject<ActivatedRouteSnapshot>(undefined);

    /**
     * a list of breadcrumbs to back track what has been navigated
     */
    private breadcrumbs: BreadcrumbModel[] = [new BreadcrumbModel([location.pathname])];

    //Constants
    private static readonly DATA_ROUTE: string = "/data";
    private static readonly PRIVACY_POLICY_ROUTE: string = 'https://civity.nl/privacy-statement/';
    private static readonly HOME_ROUTE: string = '/home';
    private static readonly EXPLORE_MAP_ROUTE: string = '/exploreMap';
    private static readonly START_ROUTE: string = '/';
    private static readonly CONTACT_ROUTE: string = '/custom/contact';
    private static readonly COOKIE_PRIVACY_POLICY_ROUTE: string = 'https://civity.nl/cookies/';
    private static readonly FAQ_ROUTE: string = '/custom/faq';
    private static readonly TERMS_OF_USE_ROUTE: string = '/custom/terms-of-use';

    //Organization specifics
    private static readonly VOOR_GEMEENTEN_ROUTE: string = "/custom/voor-gemeenten";

    constructor(
        private router: Router
    ) {
        router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
            this.navigationSubject.next(new NavigationResponse(router.getCurrentNavigation().extractedUrl.toString(),router.getCurrentNavigation().extras));
        })

        this.initRoutingTracking();
        this.initBreadcrumbTracking();
    }

    /**
     * A function to monitor the paths the router makes
     * @returns a stream of navigation responses
     */
    public listenToNavigation(): Observable<NavigationResponse> {
        return this.navigationSubject.asObservable();
    }

    public listenToSnapshots(): Observable<ActivatedRouteSnapshot>{
        return this.snapshotSubject.asObservable();
    }

    /**
     * subscribes to router events, which will add a path to the navigationSubject
     */
    private initRoutingTracking():void{
        this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
            this.navigationSubject.next(new NavigationResponse(this.router.getCurrentNavigation().extractedUrl.toString(), this.router.getCurrentNavigation().extras));
        })
        this.router.events.pipe(filter(e => e instanceof ActivationStart)).subscribe((activation:ActivationStart) => {
            this.snapshotSubject.next(activation.snapshot)
        })
    }

    /**
     * Listens to navigations made and saves the data in a breadcrumbs list to later back track.
     */
    private initBreadcrumbTracking():void{
        
        this.listenToNavigation().subscribe(route => {
            this.breadcrumbs.push(new BreadcrumbModel([route.path.split('?')[0]], route.extras));
        });

        //snapshots are available AFTER a navigationStart, so they have to be added after
        this.listenToSnapshots().subscribe(snapshot => {
            if(this.breadcrumbs.length > 0){
                const lastCrumb:BreadcrumbModel = this.breadcrumbs[this.breadcrumbs.length-1];
                if(lastCrumb.extras == undefined){
                    lastCrumb.extras = {};
                }
                if(snapshot != undefined){
                    lastCrumb.extras.queryParams = snapshot.queryParams;
                }
            }
        })
    }

    
    public getLastPath():string{
        return this.navigationSubject.getValue().path;
    }

    /**
     * Navigate to a path
     * @param path a single path to navigate to
     * @param extras add navigation extras such as query parameters or settings
     */
    private navigate(path: string, extras?: NavigationExtras): void {
        this.navigateMulti([path], extras);
    }

    /**
     * Navigate to a path
     * @param paths a list of paths, all paths after the first will be used as parameters
     * @param extras add navigation extras such as query parameters or settings
     */
    private navigateMulti(paths: string[], extras?: NavigationExtras): void {
        this.router.navigate(paths, extras);
    }

    /**
     * Navigate to the /data page.
     * @param extras add navigation extras such as query parameters or settings
     * @param key in combination with a value. Can be used to filter on type or other key/values
     * @param value in combination with a key. Can be used to filter on type or other key/values
     */
    public toData(extras?: NavigationExtras, key?: string, value?: string): void {
        if (key != undefined && value != undefined) {
            this.navigate(`${NavigationService.DATA_ROUTE}/${key}/${value}`, extras);
        } else {
            this.navigate(NavigationService.DATA_ROUTE, extras);
        }
    }

    /**
     * Navigate to the /data/{id} details page.
     * @param id add the id of the dataset you would like to navigate to
     * @param extras add navigation extras such as query parameters or settings
     */
    public toDatasetDetail(id: string, extras?: NavigationExtras): void {
        this.navigate(`${NavigationService.DATA_ROUTE}/${id}`, extras);
    }

    public toCookiePrivacyPolicy(): void {
        window.open(NavigationService.COOKIE_PRIVACY_POLICY_ROUTE, '_blank');
    }

    public toHome(): void {
        this.navigate(NavigationService.HOME_ROUTE);
    }

    /**
     * This function adds parameter
     * @param extras add parameters you want the current path to have
     */
    public addParams(extras: NavigationExtras): void {
        this.navigateMulti([], extras);
    }

    public toExploreMap(extras?: NavigationExtras): void {
        this.navigate(NavigationService.EXPLORE_MAP_ROUTE, extras);
    }

    public toStart(extras?: NavigationExtras): void {
        this.navigate(NavigationService.START_ROUTE, extras);
    }

    /**
     * Navigate back to the previous breadcrumb.  
     * This can be use full for 'navigate back' buttons.
     */
    public goBack(): void {
        if (this.breadcrumbs.length > 1) {
            this.breadcrumbs.splice(-2);
            const previousBreadcrumb: BreadcrumbModel = this.breadcrumbs[this.breadcrumbs.length - 2];
            this.navigateMulti(previousBreadcrumb.paths, previousBreadcrumb.extras);
            this.breadcrumbs.splice(-2); //get rid of new created crumb of a previous path
        }
    }

    //Constants references

    public getContactRoute(): string {
        return NavigationService.CONTACT_ROUTE;
    }

    public getPrivacyRoute(): string {
        return NavigationService.PRIVACY_POLICY_ROUTE;
    }

    public getFAQRoute(): string {
        return NavigationService.FAQ_ROUTE;
    }

    public getTermsOfUseRoute(): string {
        return NavigationService.TERMS_OF_USE_ROUTE;
    }

    public getDataRoute(): string {
        return NavigationService.DATA_ROUTE;
    }

    //Organization specific
    public getVoorGemeentenRoute(): string {
        return NavigationService.VOOR_GEMEENTEN_ROUTE;
    }
}