      <div fxLayout="column" fxFlex=60>
        <br />
        <p class="mat-headline" style="border-bottom: 1px solid #b3b2b2;">Welkom op het Fries Open Data Platform! </p>
        <hr>
        <p style="text-align: justify;">
          Op het Fries Open Data Platform vindt u een overzicht van de beschikbare open data van de Provincie Fryslân,
          de Gemeente
          Leeuwarden en het Fries historisch en letterkundig centrum Tresoar. Het kan zijn dat de data die u zoekt (nog)
          niet openbaar
          of beschikbaar is. In dat geval horen we dat graag om het aanbod uit te breiden. Ook bij vragen,
          kwaliteitsproblemen met de data, verbeterpunten, ideeën en suggesties, vragen we u
          <a href="mailto:werkgroep@open.frl?subject=Reactie%20Fries%20open%20data%20platform">contact&nbsp;</a>
          met ons op te nemen <br>
          <br>
          Het Fries Open Data Platform is het resultaat van regionale samenwerking om meer open data op een makkelijke
          manier beschikbaar
          te stellen. Samen werken we ook aan standaardisatie van datasets, en aan het uitbreiden van het aantal
          beschikbare datasets.
          De huidige deelnemers aan het Fries Open Data platform nodigen de overige Friese gemeenten en het Wetterskip
          Fryslân uit zich
          ook aan te sluiten bij de samenwerking, om zo meer Friese data voor iedereen beschikbaar te stellen.
        </p>
        <br>
        <p class="mat-subheading-1">Waardevol gebruik door u</p>
        <p style="text-align: justify;">Om hun werk te kunnen doen, verzamelen de Provincie Fryslân, de Gemeente
          Leeuwarden en Tresoar veel
          verschillende soorten gegevens. Data die waardevol en interessant kan zijn voor burgers, maatschappelijke
          organisaties,
          bedrijven, journalisten, en onderzoekers. Zoals gedetailleerde kaarten, verkeersgegevens, resultaten van
          bewonersonderzoeken,
          historische fotocollecties en meer. Als u Open data gebruikt heeft dat sociale en economische waarde. Daarom
          stellen we in dit
          platform deze openbare data zo veel en zo gemakkelijk mogelijk beschikbaar. Maakt u gebruik van Friese open
          data, vragen we u ons
          dat te laten weten. Voorbeelden van hoe open data bruikbaar is helpt ons het aanbod uit te breiden, en brengt
          anderen op ideeën
          hoe zij ook open data kunnen gebruiken.
        </p>
        <br>
        <p class="mat-subheading-1">Gebruiksvoorwaarden</p>
        <p style="text-align: justify;">De deelnemers aan het Fries Open Data Platform volgen nationale regelgeving en
          het nationale beleid
          t.a.v. open data. Dat betekent dat iedereen – particulier en bedrijf – de open data van het Fries Open Data
          Platform mag hergebruiken
          in eigen toepassingen. De open data is gratis en zonder dat u uw gegevens hoeft achter te laten beschikbaar.
          Alle open data is beschikbaar
          met een
          <a href="https://creativecommons.nl/publiek-domein/" target="_blank">CC0 licentie</a>
          , of soms met een
          <a href="https://creativecommons.org/licenses/by/3.0/nl/" target="_blank">CC-BY licentie</a>
          , waarmee we dat in juridische termen bevestigen. Dit is telkens vermeld bij de datasets zelf.
          <br>
          De Provincie Fryslân, de Gemeente Leeuwarden en Tresoar stellen de inhoud van de datasets met de grootst
          mogelijke zorg samen.
          Dit is echter geen garantie dat deze datasets volledig, accuraat en juist zijn. De provincie Fryslan, de
          gemeente Leeuwarden en
          Tresoar sluiten iedere aansprakelijkheid uit voor welke schade dan ook, direct en/of indirect, op enige wijze
          ontstaan door en/of
          voortvloeiend uit elk gebruik van deze datasets, waaronder ook – maar niet alleen – handelingen van een
          gebruiker van de datasets
          die op welke manier dan ook zijn ingegeven door de hier geplaatste informatie.
        </p>
        <p></p>
        <p></p>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <p class="mat-subheading-1">Open data en privacy</p>
          <p style="text-align: justify;">Uiteraard moet u er op kunnen vertrouwen dat de overheid uw gegevens
            beschermt. Persoonsgegevens,
            vertrouwelijke of bedrijfsgevoelige gegevens zijn niet openbaar en worden dus niet verspreid. Wat openbaar
            is en open data kan zijn
            is wettelijk geregeld in o.a. de
            <a href="http://wetten.overheid.nl/BWBR0005252/2016-10-01" target="_blank">Wet Openbaarheid van Bestuur</a>
            en de
            <a href="http://wetten.overheid.nl/BWBR0036795/2016-10-01" target="_blank">Wet Hergebruik
              Overheidsgegevens</a>.
          </p>
          <br>
          <p class="mat-subheading-1">Meer open data beschikbaar?</p>
          <p style="text-align: justify;">De deelnemers in het platform hebben meer data dan nu gepubliceerd wordt. We
            streven naar gestage
            uitbreiding. Heeft u behoefte aan bepaalde gegevens? Laat het ons weten! Open data zijn pas nuttig als u en
            anderen ze gebruiken.
            Daarom is uw vraag naar data belangrijk voor ons. Mail ons op <a href="mailto:werkgroep@open.frl"
              target="_blank">werkgroep@open.frl</a> of kom naar de
            <a href="http://www.meetup.com/Open-Data-Drinks-Nederland/" target="_blank">maandelijkse bijeenkomsten van
              open.frl</a>, we
            horen graag van u.
          </p>
          <br>
          <p class="mat-subheading-1">Iedere Friese Gemeente welkom</p>
          <p style="text-align: justify;">We verwelkomen graag andere Friese overheden om deel te nemen in het platform.
            Dit maakt de data van
            ons allemaal vindbaarder, en maakt de kans groter dat die open data nuttig wordt gebruikt door burgers,
            organisaties en bedrijven.
            Het reduceert bovendien de beheerslasten en kosten per deelnemer, en maakt het makkelijker samen het data
            aanbod uit te breiden. Lees
            <a [routerLink]="getVoorGemeentenRoute()">meer over wat het Fries Open Data Platform kan betekenen voor uw
              Gemeente</a>.
          </p>
        </div>
      </div>