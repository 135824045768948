import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  language: string;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(x => {
      this.language = this.translateService.currentLang;
    });
  }

}
