import { Component, OnInit } from '@angular/core';
import { OrganizationsService } from './organizations.service';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { OrganizationModel } from '@shared/models/organization.model';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  private organizations: OrganizationModel[] = [];
  private showLoading: boolean;

  constructor(
    private organizationsService: OrganizationsService,
    private dynamicInjectorService:DynamicInjectorService
    ) {
    this.showLoading = true;
  }
  ngOnInit() {
    this.organizationsService.getOrganizations().subscribe(organizations => {
      organizations.forEach(o => {
        this.organizations.push(o);
      });
      if (this.dynamicInjectorService.getEnvironment().name === 'Dataplatform') {
        this.organizations.sort((a, b) => b.package_count - a.package_count);
      }
       this.showLoading = false;
    });
  }

  public getOrganizations():OrganizationModel[]{
    return this.organizations;
  }

  public isShowLoading():boolean{
    return this.showLoading;
  }
}
