<!-- Accessibility Title -->
<h1 class="accessibility-hidden-title">Privacy policy</h1>

<div class="privacy-content">

<div *ngIf="language === 'sv'" fxLayoutAlign="center">
   <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
      <p style="text-align: justify;">
         Vi använder besökarstatistik (cookies) för att anaylsera hur vår webbplats används. 
         Detta gör att vi kan förbättra vår hemsida genom att göra ändringar som kan anpassas till dig på bästa sätt.
      </p>
      <p style="text-align: justify;">
         Cookies är små textfiler som lagras på din dator, vilket gör det möjligt för oss att samla besökarstatistik. 
         Vår cookie innehåller ett register över din IP-adress, men inga personliga uppgifter hämtas eller lagras. 
         All information som lagras i vår cookie kan aldrig spåras till en viss person. 
      </p>
      <p style="text-align: justify;">
         Vi använder ett analysverktyg som heter Matomo för att analysera besökarstatistik. 
         Matomo fokuserar på saker som: En webbplats tekniska tillgänglighet (den tid det tar att ladda en webbsida) och om frågor besvaras eller skickas via formulär.
      </p>
      <p style="text-align: justify;">
         Om du väljer att inte acceptera användningen av cookies på vår hemsida, kan du avvisa dem. 
         Du kan göra det via meddelandet längst ner på skärmen. 
         Genom att inaktivera cookies, samlas ingen statistik och eventuella uppgifter som ingår i något formulär på denna webbplats analyseras då ej.
      </p>
         <br>
   </div>
</div>

<div *ngIf="language === 'nl'" fxLayoutAlign="center">
   <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
      <p style="text-align: justify;">
         We gebruiken bezoekersstatistieken (cookies) om te analyseren hoe onze site wordt gebruikt. 
         Dit stelt ons in staat om onze website te verbeteren door wijzigingen aan te brengen die op de best mogelijke manier aan u kunnen worden aangepast
      </p>
      <p style="text-align: justify;">
         Cookies zijn kleine tekstbestanden die op uw computer worden opgeslagen, waardoor we bezoekersstatistieken kunnen verzamelen. 
         Onze cookie bevat een register van uw IP-adres, maar er worden geen persoonlijke gegevens opgehaald of opgeslagen. 
         Alle informatie die is opgeslagen in onze cookie kan nooit worden herleid tot een bepaalde persoon.
      </p>
      <p style="text-align: justify;">
         We gebruiken een analysetool genaamd Matomo om bezoekersstatistieken te analyseren. 
         Matomo richt zich op zaken als: de technische beschikbaarheid van een website (de tijd die nodig is om een ​​webpagina te laden) en of vragen worden beantwoord of verzonden via formulieren.</p>
      <p style="text-align: justify;">
         Als u ervoor kiest om het gebruik van cookies op onze website niet te accepteren, kunt u deze weigeren. U kunt dit doen via het bericht onderaan het scherm. 
         Door cookies uit te schakelen, worden geen statistieken verzameld en wordt informatie die in een formulier op deze website is opgenomen, niet geanalyseerd.
      </p>
         <br>
      </div>
</div>

<div *ngIf="language === 'en'" fxLayoutAlign="center">
   <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
      <p style="text-align: justify;">
         We use visitor statistics (cookies) to anaylze how our website is used. 
         This allows us to improve our website by making changes that can be adapted to you in the best way.
      </p>
      <p style="text-align: justify;">
         Cookies are small text files that are stored on your computer, which allows us to collect visitor statistics. 
         Our cookies contain a register of your IP address, but no personal details are retrieved or stored - any information stored in our cookie can never be traced to a particular person. 
      </p>
      <p style="text-align: justify;">
            We use an open source analysis tool called Matomo in order to analyze visitor statistics. 
            Matomo specifically targets things like: a website's technical availability (the time it takes to load a webpage) and if questions are answered or sent via forms.
      </p>
      <p style="text-align: justify;">
            If you choose not accept the use of cookies on our website, you may decline them. 
            You may do this via the message at the bottom of the screen. By disabling cookies, no statistics 
            are collected and any data entred into any form on this website is not analyzed.
      </p>
   </div>
</div>

</div>

 