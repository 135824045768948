<div fxLayoutAlign="center" class="ieCompat">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <div class="main app-background">
      <img class="img_landscape" [src]="getSrcPath('assets/images/architecture.jpg')" /><br />
      <br />
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" class="parent_new_box">
        <div class="new_box">
          <div>
            <h1 class="mat-headline customHeader">
              <strong>{{ "HOMEPAGE.MOST_SEEN" | translate }}</strong>
            </h1>
            <ol>
              <li *ngFor="let mostSeen of mostSeen">
                <a href="#/data/{{ mostSeen.id }}">
                  {{ mostSeen.resources[0].name }}
                </a>
              </li>
            </ol>
          </div>
          <br/>
        </div>

        <div class="new_box">
          <div>
            <h1 class="mat-headline customHeader">
              <strong>{{ "HOMEPAGE.LAST_EDITED" | translate }}</strong>
            </h1>
            <ul>
              <li *ngFor="let lastEdited of lastsEdited">
                <a href="#/data/{{ lastEdited.data.package.id }}">
                  {{ lastEdited.data.package.title }}
                </a>
                <p>
                  {{ "HOMEPAGE.LAST_EDITED" | translate }} :
                  {{ lastEdited.data.package.metadata_modified | localPipe: "dd-MM-yy hh:mm" }}
                </p>
              </li>
            </ul>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>