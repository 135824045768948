import { Component, OnInit, ElementRef } from '@angular/core';
import { DatasetsService } from '@shared/services/datasets.service';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
  selector: 'app-leeuwarden-home-text',
  templateUrl: './leeuwarden-home-text.component.html',
  styleUrls: ['./leeuwarden-home-text.component.scss']
})
export class LeeuwardenHomeTextComponent implements OnInit, ConfigInterface {

  url;
  customIframe;
  currentDate: Date;
  totalNoOfDatasets: number;

  constructor(private elementRef: ElementRef, private datasetsService: DatasetsService) {
    this.currentDate = new Date();
  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {

    this.datasetsService.getTotalNumberOfDatasets().then((result: number) => {
      this.totalNoOfDatasets = result;
    });

  }

}
