import * as _ from 'lodash';

export class Utils {

  static detectEdgeAndIE() {
    const ua = window.navigator.userAgent;

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }


    // other browser
    return false;
  }

  /**
	 * Mutates a given array by removing an element from it.
	 * @param element - Element to remove.
	 * @param array - Target array to remove the element from.
	 */
  static removeElementFromArray<T>(element: T, array: T[]) {
    // check if the given array is an actual array and has elements, otherwise do nothing
    if (Array.isArray(array) && array.length > 0) {
      let new_array = array.filter(iterator => iterator !== element);
      array.length = 0;
      array.push(...new_array)
    }
  }
  

   /**
	 * Mutates a given array by removing an array of elements from it.
	 * @param elements - Array of elements to remove.
	 * @param array - Target array to remove the elements from.
	 */
  static removeElementsFromArray<T>(elements: T[], array: T[]) {
    if (Array.isArray(elements) && elements.length > 0) {
      elements.forEach(iterator => Utils.removeElementFromArray(iterator, array));
    }
  }  

}
