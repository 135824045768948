import { Component, OnInit } from '@angular/core';
import { DatasetsService, WishListItem } from '@shared/services/datasets.service';
import { KeycloakUserService } from '@shared/services/keycloak-user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'shoppingCart-lab',
  templateUrl: './shoppingCart.component.html',
  styleUrls: ['./shoppingCart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  
  wishlist: WishListItem[] = [];
  authorized: boolean
  constructor(
    private keycloakUserService: KeycloakUserService,
    private datasetsService: DatasetsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.refreshWishList()
    // load value from KeycloakUserServiceResolver to resolve component's lazy-loading 
    this.authorized = this.route.snapshot.data.isAuthorized;


  }

  isAuthorized(): boolean {
    let authorized = this.keycloakUserService.isAuthorized()
    if (authorized == undefined){
      authorized = this.authorized
    }
    return authorized;
  }

  userLogin():Promise<void> {
    return this.keycloakUserService.login()
  }
  
  refreshWishList() {
    this.wishlist = this.datasetsService.getWishListContent();
  }

  isWishListEmpty(): boolean {
    return this.datasetsService.isWishListEmpty()
  }

  requestItem(){
    console.debug('add to dataset requests!')

  }

  dismissItem(id){
    this.datasetsService.removeItemFromWishList(id)
    this.refreshWishList()
  }
}
