import { CommonModule } from '@angular/common';
import { CustomComponent } from './custom/custom-components/custom-component/custom.component';
import { CustomRoutingModule } from './custom/custom-routing.module';
import { NgModule } from '@angular/core';

//Customer components
import { CasesComponent } from './organizations/dataplatform/custom-pages/cases/cases.component';
import { ContactComponent } from './organizations/dataplatform/custom-pages/contact/contact.component';
import { DataplatformContactService } from './organizations/dataplatform/custom-pages/contact/contact.service';
import { DataVisualizationComponent } from './organizations/dataplatform/custom-pages/data-visualization/data-visualization.component';
import { FaqComponent } from './organizations/dataplatform/custom-pages/info/faq/faq.component';
import { FodaComponent } from './organizations/friesland/custom-pages/foda/foda.component';
import { ForMunicipalitiesComponent } from './organizations/friesland/custom-pages/for-municipalities/for-municipalities.component';
import { GebuiksvoorwaardenComponent } from './organizations/dataplatform/custom-pages/info/gebuiksvoorwaarden/gebuiksvoorwaarden.component';
import { GISViewerAuthenticationDialog } from '../portal-config/custom/custom-components/gisviewer-authentication-dialog/gis-viewer-authentication-dialog.component';
import { GISViewerComponent } from './organizations/exposome/custom-pages/gisviewer/gisviewer.component';
import { KlimaatMonitorComponent } from './organizations/greeninnovationhub/custom-pages/klimaatmonitor/klimaatmonitor.component';
import { LabComponent } from './organizations/dataplatform/custom-pages/lab/lab.component';
import { Map2dComponent } from './organizations/sandbox/custom-pages/map2d/map2d.component';
import { Map3dComponent } from './organizations/sandbox/custom-pages/map3d/map3d.component';
import { OrganizationCardComponentMalmo } from './organizations/malmo/custom-pages/organization-card/organization-card.component';
import { OrganizationCardProvincieNoordHollandComponent } from './organizations/provincie-noord-holland/custom-pages/organization-card/organization-card.component';
import { OrganizationComponentMalmo } from './organizations/malmo/custom-pages/organization/organization.component';
import { OrganizationProvincieNoordHollandComponent } from './organizations/provincie-noord-holland/custom-pages/organization/organization.component';
import { OrganizationsCardComponent } from './organizations/dataplatform/custom-pages/organizations-card/organizations-card.component';
import { OrganizationsComponent } from './organizations/dataplatform/custom-pages/organizations/organizations.component';
import { PrivacyComponent } from './organizations/dataplatform/custom-pages/info/privacy/privacy.component';
import { RegionalHistoricalCenterComponent } from './organizations/stichtsevecht/custom-pages/regional-historical-center/regional-historical-center.component';
import { SharedModule } from '@shared/shared.module';
import { ShoppingCartComponent } from './organizations/health-ri/subpaths/shoppingCart/shoppingCart.component';
import { VoorbeeldenDenhaagComponent } from './organizations/denhaag/custom-pages/voorbeelden/voorbeelden.component';

@NgModule({
  imports: [
    CommonModule,
    CustomRoutingModule,
    SharedModule
  ],
  declarations: [
    CasesComponent,
    CasesComponent,
    ContactComponent,
    ContactComponent,
    CustomComponent,
    DataVisualizationComponent,
    DataVisualizationComponent,
    FaqComponent,
    FaqComponent,
    FodaComponent,
    ForMunicipalitiesComponent,
    GebuiksvoorwaardenComponent,
    GISViewerComponent,
    KlimaatMonitorComponent,
    LabComponent,
    Map2dComponent,
    Map3dComponent,
    OrganizationCardComponentMalmo,
    OrganizationCardProvincieNoordHollandComponent,
    OrganizationComponentMalmo,
    OrganizationProvincieNoordHollandComponent,
    OrganizationsCardComponent,
    OrganizationsComponent,
    PrivacyComponent,
    RegionalHistoricalCenterComponent,
    ShoppingCartComponent,
    VoorbeeldenDenhaagComponent    
  ],
  providers: [DataplatformContactService]
})

export class CustomModule { }

/**
 * Used inside shared module, to load components from the config inside the shared module.
 */
@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    GISViewerAuthenticationDialog
  ],
  declarations: [
    GISViewerAuthenticationDialog
  ]
})
export class CustomSharedModule{ }
