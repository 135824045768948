import { ResourceModel } from './resource.model';
import { OrganizationModel } from './organization.model';
import { ExtrasModel } from './extras.model';
import { LabelClass } from './scheming-package.model';

export class DatasetModel {
  id: string;
  title: string;
  num_resources: number;
  resources: ResourceModel[];
  organization: OrganizationModel;
  notes: string;
  private: boolean;
  tags: DatasetTagModel[];
  extras: ExtrasModel[];
  total_views: number;
  rating: number;
  ratings_count: number;
  metadata_modified: Date;
  url: string;
  author: string;
  state: string;
  contact_point_name: string;
  contact_point_email: string;
  maintainer_email: string;
  publisher_uri: string;
  publisher_email: string;
  author_email: string;
  metadata_created: Date;
  package_id: string;
  result: string;
  language: string;
  provider_contact: string;
  geo_ref_system: string;              // check this field -> resources.values.geo_enabled ?
  status: string;
  // these above fields were not added from the beginning
  contact_uri: string;
  data_owner: string;
  guid: string;
  publisher_type: string;
  publisher_url: string;
  spatial_uri: string;
  uri: string;
  spatial: string;
  theme: string;
  access_rights: string;
  frequency: string;
  has_version: string;
  created: Date;
  last_updated: Date;
  license_title: string;
  license_url: string;
  tracking_summary: TrackingSummary;
  groups: DatasetGroupModel[];
  type: string;
  linked_datasets: string;
  relationships_as_object?:DatasetRelationshipModel[];
  relationships_as_subject?:DatasetRelationshipModel[];
}

class TrackingSummary {
  total: number;
  recent: number;
}

export class DatasetRelationshipModel{
  comment: string;
  type: RelationshipType;
  id:string;
  __extras: DatasetRelationshipExtrasModel;
}

class DatasetRelationshipExtrasModel{
  object_package_id?:string;
  revision_id?:string;
  subject_package_id?:string;
}

export declare enum RelationshipType {
  dependsOn = 'depends_on',
  dependencyOf = 'dependency_of',
  derivesFrom = 'derives_from',
  hasDerivation = 'has_derivation',
  linksTo = 'links_to',
  linkedFrom = 'linked_from',
  childOf = 'child_of',
  parentOf = 'parent_of'
}

export class DatasetGroupModel{
  display_name: string;
  description: string;
  image_display_url: string;
  title: string;
  id: string;
  name: string;
}

export class DatasetTagModel{
  vocabulary_id: string;
  state: string;
  display_name: string;
  id: string;
  name: string;
}

export class DatasetSchema{
  about:string;
  about_url:string;
  dataset_fields:ResourceFieldModel[];
  dataset_type:string;
  resource_fields:ResourceFieldModel[];
  scheming_version:number;
}

export class ResourceFieldModel{
  field_name:string;
  form_placeholder: LabelClass;
  form_snippet:string;
  label: LabelClass;
  preset:string;
  required?:boolean;
  upload_clear?:string;
  upload_field?:string;
  upload_label?:string;
  validators:string;
  form_attrs?:{
    class:string;
    'data-module':string;
  }
  help_inline?:boolean;
  help_text?:LabelClass;

}
