      <div fxLayout="column" fxFlex=65>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #0571b3;">Open data register provincie Noord-Holland
        </h1>
        <p style="text-align: justify;">Welkom bij het open data register van de provincie Noord-Holland.
          In dit register kan gezocht worden op beschikbare open data van de provincie.
          Het register bevat alleen verwijzingen naar de brondata, het downloaden van de brondata kan via het volgen van
          de link in het register.
          Direct inzien en downloaden van data kan via de andere ingangen die op de website van de provincie te vinden
          zijn.
          Er is een portaal voor <a
            href="https://maps.noord-holland.nl/kaartenportaal/apps/MapSeries/index.html?appid=d8c0eb7751444d4b9537231615ad6a09&entry=2"
            target="_blank">interactieve kaarten</a> en een portaal voor <a href="https://cijfers.noord-holland.nl/jive"
            target="_blank">dashboards, cijfers en feiten</a>.</p>
        <p style="text-align: justify;">Bij het open- en beschikbaarstellen van open data betracht de provincie uiterste
          zorgvuldigheid ten aanzien van de privacy en veiligheid.
          De provincie houdt zich aan de Algemene Verordening Gegevensbescherming (AVG). De provincie publiceert geen
          gegevens die persoonlijke, vertrouwelijke of bedrijfsgevoelige informatie bevatten.
          Ook gegevens die schade kunnen toebrengen aan de veiligheid worden niet als open data gepubliceerd. </p>
        <p style="text-align: justify;">Voor vragen, feedback en opmerkingen over het open register kan een mail
          gestuurd worden naar: <a href="mailto:dataregister@noord-holland.nl">dataregister@noord-holland.nl&nbsp;</a>
        </p>
        <br>
        <p class="mat-h2">Gebruiksvoorwaarden</p>
        <p style="text-align: justify;">Open data kan door ieder bedrijf of elke burger (her)gebruikt worden voor eigen
          doeleinden.
          De data wordt gratis door de provincie ter beschikking gesteld. De data is met uiterste zorg samengesteld.
          Zoveel mogelijk is een goede weergave meegegeven over de juistheid, volledigheid en actualiteit.
          De provincie wijst elke aansprakelijkheid af voor wat voor schade ook, direct en/of indirect, op enigerlei
          wijze ontstaan door en/of voortvloeiend uit elk gebruik van deze data(sets), waaronder ook – maar niet alleen
          – handelingen van een gebruiker van de data(sets) die op welke manier dan ook zijn ingegeven door de hier ter
          beschikking gestelde informatie.</p>
        <br>
      </div>

      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <h1 class="mat-headline" style="word-break: break-word">Open data en privacy</h1>
          <p style="text-align: justify;">
            De provincie Noord-Holland gaat zorgvuldig om met de gegevens van haar inwoners en
            houdt zich aan de Wet bescherming persoonsgegevens. Bij open data gaat het alleen om
            informatie die openbaar gemaakt mag worden, dus geen persoonlijke, vertrouwelijke of
            bedrijfsgevoelige gegevens. Dus geen gegevens over personen. Informatie die schade
            kan veroorzaken of de veiligheid in gevaar kan brengen wordt ook niet via open data
            verspreid.<br>
          <h1 class="mat-headline" style="word-break: break-word">Groeimodel</h1>
          <p style="text-align: justify;">
            De provincie Noord-Holland zal meer en meer data als open data beschikbaar stellen.
            Niet alle open data is al via het register te vinden of in te zien.
            Voor aanvullende data, vooral documenten en rapporten, zie ook de website van de <a
              href="https://www.noord-holland.nl/" target="_blank">provincie</a>. <br>
          </p>
          <br>
        </div>
      </div>