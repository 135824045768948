import { AuthConfig } from "./organization.config.model";

export const TST = 'tst';
export const ACC = 'acc';
export const PRD = 'prd';

export class Environment {
    production: boolean;
    environment: string;
    organization: string;
    name: string;
    apiUrl: string;
    supportApi: string;
    matomoSiteId: number;
    matomoUrl: string;
    organizationId: string;
    version: string;
    auth?: AuthConfig;
    exposomeAuth?: ExposomeAuth;
}

//List all urls into different environments
export class EnvironmentDependantString {
    [TST]: string | string[];
    [ACC]: string | string[];
    [PRD]: string | string[];

    constructor(tst: string | string[], acc: string | string[], prd: string | string[]) {
        this[TST] = tst;
        this[ACC] = acc;
        this[PRD] = prd;
    }
}

export class EnvironmentDependantNumber {
    [TST]: number;
    [ACC]: number;
    [PRD]: number;

    constructor(tst: number, acc: number, prd: number) {
        this[TST] = tst;
        this[ACC] = acc;
        this[PRD] = prd;
    }
}


export class ExposomeAuth {
    url: EnvironmentDependantString;
    constructor(json:Object){
        const urlObject:Object = json['url'];
        this.url = new EnvironmentDependantString(urlObject[TST], urlObject[ACC], urlObject[PRD]);
    }
}


