<div [ngSwitch]="customerName">

  <!-- get rid of malmo specific -->
  <div *ngSwitchCase="'Malmo'">
    <button mat-button color="primary" class="link-button" (click)="expand.description = !expand.description">
      <div fxLayout="row">
        <mat-icon class="align-bottom">description_outline</mat-icon>
        <p>&nbsp;&nbsp;{{'DATA.DESCRIPTION' | translate}}</p>
        <mat-icon class="align-center">{{expand.description? 'arrow_drop_up': 'arrow_drop_down'}}</mat-icon>
      </div>
    </button>

    <mat-expansion-panel class="mat-elevation-z0  app-background" [disabled]="true"
      [expanded]="expand.description && datasetDetails.notes !== ''">
      <mat-panel-description>
        <markdown [data]="datasetDetails.notes" class="a"></markdown>
      </mat-panel-description>
    </mat-expansion-panel>

    <button mat-button color="primary" class="no-cursor no-margin">
      <div fxLayout="row">
        <mat-icon class="align-bottom">label</mat-icon>
        <p>&nbsp;&nbsp;{{'DATA.TAGS' | translate}}</p>
      </div>
    </button>

    <div *ngIf="datasetDetails.tags.length>0" class="m2">
      <mat-chip-list>
        <div *ngFor="let tag of datasetDetails.tags">
          <mat-chip class="hover-element" (click)="addQueryParam(tag.name)">
            {{tag.name}}
          </mat-chip>
        </div>
      </mat-chip-list>
    </div>
    <br>

    <button mat-button color="primary" class="link-button" (click)="expand.metadata = !expand.metadata">
      <div fxLayout="row">
        <mat-icon class="align-bottom">description_outline</mat-icon>
        <p>&nbsp;&nbsp;{{'DATA.METADATA' | translate}}</p>
        <mat-icon class="align-center">{{expand.metadata? 'arrow_drop_up': 'arrow_drop_down'}}</mat-icon>
      </div>
    </button>

    <ng-template #Loading>
      <div style="display: flex; justify-content: center; align-items: center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>

    <mat-expansion-panel class="mat-elevation-z0 app-background" [disabled]="true" [expanded]="expand.metadata">
      <app-metadata-table [datasetDetails]="datasetDetails"></app-metadata-table>
    </mat-expansion-panel>
    <br>
    <a>
      <mat-icon class="align-bottom">bar_chart</mat-icon>
      <div class="mat-button no-cursor">
        <p>{{'DATA.STATISTICS' | translate}}
        </p>
      </div>
    </a>
    <mat-label>
      <p>{{'DATA.LATEST_UPDATE' | translate}}
        <span ng-app ng-controller="Ctrl"> {{datasetDetails.metadata_modified | localPipe :'dd MMMM yyyy' | translate}}
        </span>
      </p>
    </mat-label>
    <!-- <mat-label *ngIf="datasetDetails.tracking_summary">
      <p>{{'PAGE.VIEWS' | translate}}
        <span ng-app ng-controller="Ctrl">{{datasetDetails.tracking_summary.total}}</span>
      </p>
    </mat-label> -->
    <app-dataset-rating
      style="font-family: Open Sans; font-size: 18px; font-weight: 400; line-height: 27px; color: #777;"
      [datasetDetails]="datasetDetails"></app-dataset-rating>
  </div>


  <div *ngSwitchDefault>
    
    <div *ngIf="isWishlistEnabled()" class="no-cursor no-margin">
      <div fxLayout="row" style="font-weight:400; font-size:15px;">
        <button id="wishlistButton" color="primary" mat-button (click)="isItemInWishlist(datasetDetails.id) ? removeFromWishlist(datasetDetails.id) : addToWishlist(datasetDetails)">
          <ng-container *ngIf="isItemInWishlist(datasetDetails.id) else NO_WISHLIST">
            <mat-icon class="align-center" color="primary">favorite</mat-icon>&nbsp;&nbsp;Wishlist
          </ng-container>
          <ng-template #NO_WISHLIST>
            <mat-icon class="align-center" color="primary">favorite_border</mat-icon>&nbsp;&nbsp;Wishlist
          </ng-template>
        </button>

      </div>
    </div>

    <mat-expansion-panel class="mat-elevation-z0  app-background"
      [expanded]="expand.description && datasetDetails.notes !== ''" (opened)="expand.description = true"
      (closed)="expand.description = false" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="align-bottom" color="primary">description_outline</mat-icon>&nbsp;&nbsp;{{'DATA.DESCRIPTION' | translate}}
          <mat-icon class="align-center" color="primary">{{expand.description? 'arrow_drop_up': 'arrow_drop_down'}}</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-panel-description>
        <markdown [data]="datasetDetails.notes" class="a"></markdown>
      </mat-panel-description>
    </mat-expansion-panel>

    <div class="no-cursor no-margin">
      <div fxLayout="row" style="font-weight:400; font-size:15px;">
        <mat-icon class="align-bottom" color="primary">label</mat-icon>&nbsp;&nbsp;{{'DATA.TAGS' | translate}}
      </div>
    </div>

    <div *ngIf="datasetDetails.tags.length>0" class="m2">
      <mat-chip-list>
        <div *ngFor="let tag of datasetDetails.tags">
          <mat-chip class="hover-element" (click)="addQueryParam(tag.name)">
            {{tag.name}}
          </mat-chip>
        </div>
      </mat-chip-list>
    </div>
    <br>

    <ng-template #Loading>
      <div style="display: flex; justify-content: center; align-items: center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>

    <mat-expansion-panel class="mat-elevation-z0  app-background" [expanded]="expand.metadata"
      (opened)="expand.metadata = true" (closed)="expand.metadata = false" hideToggle>

      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="align-bottom" color="primary">description_outline</mat-icon>&nbsp;&nbsp;{{'DATA.METADATA' | translate}}
          <mat-icon class="align-center" color="primary">{{expand.metadata? 'arrow_drop_up': 'arrow_drop_down'}}</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-metadata-table [datasetDetails]="datasetDetails"></app-metadata-table>

    </mat-expansion-panel>

    <a>
      <mat-icon class="align-bottom">bar_chart</mat-icon>
      <div class="mat-button no-cursor">{{'DATA.STATISTICS' | translate}}</div>
    </a>
    <br>
    <mat-label>{{'DATA.LATEST_UPDATE' | translate}}
      <span ng-app ng-controller="Ctrl"> {{datasetDetails.metadata_modified | localPipe :'dd MMMM yyyy' | translate}}
      </span>
    </mat-label>
    <br>
    <mat-label *ngIf="datasetDetails.tracking_summary">{{'PAGE.VIEWS' | translate}}
      <span ng-app ng-controller="Ctrl">{{datasetDetails.tracking_summary.total}}</span>
    </mat-label>
    <br>
    <app-dataset-rating [datasetDetails]="datasetDetails"></app-dataset-rating>
  </div>

</div>