      <div fxLayout="column" fxFlex=60>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Open Data</h1>
        <p style="text-align: justify;">
          Schiedam wil niet alleen zaken vóór inwoners en ondernemers doen, maar vooral ook sámen
          met hen doen en ze zelf de mogelijkheid geven om initiatieven te ontwikkelen. De gemeente
          beschikt over zeer uiteenlopende data, die opengesteld worden via deze portal.
        </p>
        <br>
        <h2 class="mat-headline">Wat is open data?</h2>
        <p style="text-align: justify;">
          De gemeente is eigenaar van grote hoeveelheden gegevens en informatie, die zij
          nodig heeft voor de uitvoering van gemeentelijke taken. Een groot deel van deze
          gemeentelijke informatie is openbaar, maar in veel gevallen zijn de gegevens voor
          burger en bedrijfsleven niet makkelijk digitaal toegankelijk of (her)bruikbaar.
          Open data is de term die gebruikt wordt om (een deel van) deze gegevens en
          informatie beschikbaar en toegankelijk te maken voor iedereen.<br>
          <br>
          Open data is overheidsinformatie die:
        </p>
        <ul class="text-color">
          <li>verzameld is in het kader van de uitvoering van een publieke taak;</li>
          <li>gefinancierd is met publieke middelen voor de uitvoering van die taak;</li>
          <li>openbaar is op grond van de Wet openbaarheid van bestuur;</li>
          <li>bij voorkeur voldoet aan 'open standaarden'; </li>
          <li>bij voorkeur door een computer kan worden gelezen.</li>
        </ul>
        <br>
        <p style="text-align: justify;">
          (bron: <a class="target_blank" href="http://data.overheid.nl/" target="_blank">http://data.overheid.nl/</a>)
        </p>
        <h2 class="mat-headline">Meer bronnen</h2>
        <p style="text-align: justify;">
          Dit "dataplatform" is niet voor alle soorten data het meest geschikte kanaal.
          De gemeente biedt meer data aan via <a href="https://www.schiedam.nl/a-tot-z/open-data">de gemeentelijke
            website</a>.
        </p>
        <p></p>
        <p></p>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <h2 class="mat-headline ">Privacy</h2>
          <p style="text-align: justify;">Uiteraard moet u er op kunnen vertrouwen dat de overheid uw gegevens
            beschermt.
            Informatie waarbij uw privacy niet is gegarandeerd, wordt daarom niet gepubliceerd.<br> Datasets worden,
            waar nodig, geanonimiseerd.
          </p>
          <br>
          <h2 class="mat-headline ">Proclaimer</h2>
          <p style="text-align: justify;">De gemeente Schiedam is verantwoordelijk voor de inhoud van deze website en
            datasets, en doet er alles
            aan deze actueel en juist te houden. Komt u desondanks toch iets tegen dat niet correct is of verouderd, dan
            stellen wij
            <a class="target_blank"
              href="https://pform.schiedam.nl/Loket/prodcat/products/getProductDetailsAction.do?name=Contactformulier"
              target="_blank">uw reactie </a> bijzonder op prijs.
          </p>
        </div>
      </div>