<div fxLayout.xs="column" fxLayoutAlign="center">
    <mat-spinner *ngIf="isShowLoading()"></mat-spinner>
</div>
<div *ngIf="!isShowLoading()" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap.xs="20px">
    <div *ngIf="!isShowLoading()" fxFlex="60" fxFlex.sm="90" fxFlex.xs="90" fxLayout.xs="column" fxLayoutGap="10px grid"
        class="gridPaddingFix flex-wrap">
        <app-organization-card-malmo *ngFor="let organization of getOrganizations(); let i = index" fxFlex="25" [index]="i"
            [organization]="organization">
        </app-organization-card-malmo>
    </div>
</div>
<br>