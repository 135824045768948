import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { homeComponents } from '../../../portal-config/home.module';
import { DatasetModel } from '@shared/models/dataset.model';
import { DatasetsService } from '@shared/services/datasets.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public newsDatasets: DatasetModel[] = [];
    /**
   * Needed to break a for loop when the conditions have been met.
   */
  private static readonly BREAK: string = 'Break';

  private templateMarkdown: string;
  @ViewChild("customerHomeText", { static: true, read: ViewContainerRef })
  public customerHomeText: ViewContainerRef

  constructor(
    private dynamicInjectorService: DynamicInjectorService,
    private datasetsService: DatasetsService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.initMarkdownTemplate();
    this.initNewsWidget()
  }

  public hasMarkdownSupport(): boolean {
    const markdownSupport: boolean = this.dynamicInjectorService.getOrganizationConfig().homepage.markdownSupport;
    const hasContent: boolean = this.getHomepageContent() !== undefined;
    return markdownSupport && hasContent;
  }
  
  public displayNews(): boolean {
    // Should return true if at least one dataset of type 'news' is returned
    const loadNewsWidget: boolean = this.dynamicInjectorService.getOrganizationConfig().homepage.displayNews;
    return loadNewsWidget != undefined && loadNewsWidget == true && this.newsDatasets.length > 0
  }

  public getNewsRoute(): string {
    return "/#/data/type/news"
  }

  public getHometextColumnWidth(): number {
    if (this.displayNews()){
      return 70
    }
    return 100
  }

  public initOrganisationHome(): void {
    try {
      homeComponents.forEach(component => {
        if (component.prototype.getPortal() == this.dynamicInjectorService.getEnvironment().organization) {
          this.customerHomeText.createComponent<Object>(component);
          throw HomeComponent.BREAK;
        };
      });
    } catch (e) {
      if (e !== HomeComponent.BREAK) throw e;
    }
  }

  private initNewsWidget(): void {
    const organizationId = this.dynamicInjectorService.getEnvironment().organizationId
    this.datasetsService.getNewsPackageSearch(organizationId).then(response => {
      response.results.forEach(result => this.datasetsService.getDatasetDetails(result.id).then(newsDataset => {
        this.newsDatasets.push(newsDataset)
      }));
    });
  }

  private initMarkdownTemplate(): void {
    if (this.dynamicInjectorService.getOrganizationConfig().homepage.markdownSupport) {
      const configOrganizationId: string = this.dynamicInjectorService.getOrganizationConfig().organizationId[this.dynamicInjectorService.getEnvironment().environment];
      const organizationId: string = configOrganizationId != undefined ? configOrganizationId.split(' ')[0] : 'no-orginazation-id-found'; //some customers have multiple organizations, this will split them and take the first.
      this.apiService.getOrganizationByName(organizationId).then(result => {
        if (result.portal_intro_text != undefined) {
          this.templateMarkdown = result.portal_intro_text;
        } else {
          this.initMarkdownSystemTemplate(organizationId);
        }
      })
        .catch(() => {
          this.initMarkdownSystemTemplate(organizationId)
        })
    } else {
      console.debug('loading markdown intro was disabled');
      this.initOrganisationHome();
    }
  }

  private initMarkdownSystemTemplate(organizationId?: string): void {
    this.apiService.getSystemIntroText().then(result => {
      if (result != undefined) {
        console.debug(`Could not find the portal intro text based on the organization: ${organizationId}. It will load the system intro text instead.`)
        this.templateMarkdown = result;
      }
    })
      .finally(() => {
        if (this.templateMarkdown == undefined || this.templateMarkdown == '') {
          console.warn('could not find any portal intro text');
          this.initOrganisationHome();
        }
      });
  }

  public getHomepageContent(): string {
    return this.templateMarkdown;
  }

  public displayThemes(): boolean {
    return this.dynamicInjectorService.getOrganizationConfig().homepage.displayThemes;
  }

  displaySearchBar(): boolean {
    return this.dynamicInjectorService.getOrganizationConfig().homepage.displaySearchBar
  }
}
