<div class="home-text">
  <div class="banner">
    <div class="banner-text">
      <h1 class="title" style="font-size: 42px; color:white;"><strong>{{ 'CUSTOMER.DEFAULT.BANNER_TITLE' | translate}}</strong></h1>
      <h2 style="font-size: 23px; color:white">
        {{ 'CUSTOMER.DEFAULT.BANNER_TEXT' | translate}}
      </h2>
    </div>
    <div class="banner-search">
      <app-search-bar></app-search-bar>
      <h2 class="dataset-counter">{{ 'CUSTOMER.DEFAULT.SEARCH_TEXT_1' | translate}} {{totalNoOfDatasets}} {{ 'CUSTOMER.DEFAULT.SEARCH_TEXT_2' | translate}} </h2>
    </div>
    <br>
  </div>
  <br>
  <div class="intro-text">
    <p class="mat-subheading-1">Open data is:</p>
    <ul>
      <li>
        <p>openbare data,</p>
      </li>
      <li>
        <p>openbare data,</p>
      </li>
      <li>
        <p>bekostigd uit publieke middelen,</p>
      </li>
      <li>
        <p>verzameld voor werkzaamheden overheidsinstantie,</p>
      </li>
      <li>
        <p>liefst ‘open standaard’; dat wil zeggen zonder barrières voor gebruik ICT-toepassingen,</p>
      </li>
      <li>
        <p>liefst leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</p>
      </li>
    </ul>
  </div>
</div>

