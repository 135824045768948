import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number): any {

    const alphanumericRegex = new RegExp(/[a-zA-Z0-9_-]/);

    while (limit < value.length && alphanumericRegex.test(value.charAt(limit))) {
      limit++;

    }

    return value.slice(0, limit);
  }

}
