import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';

export interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-sandbox-footer-text',
  templateUrl: './sandbox-footer-text.component.html',
  styleUrls: ['./sandbox-footer-text.component.scss']
})
export class SandboxFooterTextComponent implements OnInit, ConfigInterface {

  constructor(
    private dynamicInjectorService: DynamicInjectorService
  ) { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

  getAsset(path) {
    return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${path}`
  }
}
