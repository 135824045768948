import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id'

@Component({
    selector: 'app-almere-home-text',
    templateUrl: './almere-home-text.component.html',
    styleUrls: ['./almere-home-text.component.scss']
})
export class AlmereHomeTextComponent implements OnInit, ConfigInterface {

    constructor() { }
    
    getPortal(): string {
        return ID.id;
    }

    ngOnInit() {
    }

}
