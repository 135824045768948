import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  language: string;

  constructor(private translateService: TranslateService, private matomoTracker: MatomoTracker) { }

  ngOnInit() {
    const matomoCategory = `Access Privacy Policy page`;
    const matomoAction = 'Overview'
    this.matomoTracker.trackEvent(matomoCategory, matomoAction);
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(x => {
      this.language = this.translateService.currentLang;
    });
  }

}
