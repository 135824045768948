<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
      <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

        <div fxFlex="20">
          <p class="mat-subheading-1">Bezoekadres</p>
          <p class="mat-body-1">
            Stadswinkel Centrum<br>
            Coolsingel 40<br>
            3011 AD Rotterdam<br>
          </p>

        </div>

        <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">Postadres</p>
            <p class="mat-body-1">
              Postbus 70013<br>
              3000 KR Rotterdam<br>
            </p>
          </div>
        </div>

        <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">Contact</p>
            <p class="mat-body-1">
              Telefoon 14 010<br>
              <!-- WhatsApp 06 - 38 14 67 58<br> -->
              <a href="https://www.rotterdam.nl/contact/" target="_blank">https://www.rotterdam.nl/contact/</a><br>
            </p>
          </div>
        </div>

        <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61" fxLayoutAlign="space-between">
          <p class="powered-by">Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a><br></p> <br> <br>
        </div>
      </div>
    </div>    
  </mat-toolbar-row>
</mat-toolbar>