<!-- Search bar component -->
<div class="ieCompat" *ngIf="displaySearchBar()">
  <app-search-bar></app-search-bar>
</div>
<!-- Accessibility Title -->
<h1 class="accessibility-hidden-title">Home</h1>

<div fxLayoutAlign="center" class="ieCompat">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <!-- slider -->
    <app-carousel></app-carousel>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="25px">
      <div fxLayout="column" [fxFlex]="getHometextColumnWidth()">
        <br />
        <markdown *ngIf="hasMarkdownSupport();" class="markdown podcast">{{getHomepageContent()}}</markdown>
        <div #customerHomeText></div>
        <app-themes *ngIf="displayThemes()"></app-themes>
      </div>
      <!-- newWidget visibility needs some more refactoring -->
        <!-- <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth" *ngIf="displayNews()"></div> -->
        <div fxLayout="column" fxFlex=40 class="ieCustomFlex" *ngIf="displayNews()">
          <br />
          <div class="latest-news">
            <mat-card class="mat-elevation-z2 news-tab" fxLayout="column">
              <mat-card-header class="noMarginHeader news-tab-header">
                <mat-card-title class="mat-subheading-1">
                  <a [href]="getNewsRoute()"><mat-icon>feed</mat-icon>News</a>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content fxFlex fxLayout="column" fxLayoutAlign="space-between none">
                <div fxFlex fxLayoutAlign="center center">
                  <div >
                  <mat-card *ngFor="let newsDataset of getNewsPreviewDatasets(3) ; first as firstItem" class="mat-elevation-z2 news-card" fxLayout="column">
                    <mat-card-header class="noMarginHeader mat-subheading-2">
                      <mat-card-title class="news-tab-title">
                        <a href="/#/data/{{newsDataset.id }}">
                          {{ newsDataset.title }}
                        </a>
                      </mat-card-title>
                      <mat-card-subtitle  class="mat-subheading-1 news-tab-subtitle">
                        <p>{{newsDataset.metadata_created | localPipe :'dd MMMM yyyy' | translate}}</p>
                        <!-- change dataset property to correspond to news_image -->
                        <img *ngIf="firstItem" src="https://i.regiogroei.cloud/fn_checksum,7cb4c201c6adaef47921910cf1acddc7/552x310/f3344bdb-d1c2-3c84-9f53-f4b5f65d7022.jpg"/>
                      </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="news-tab-content" *ngIf="firstItem" fxFlex fxLayout="column" fxLayoutAlign="space-between none">
                      <div fxFlex fxLayoutAlign="center center">
                        <markdown data="{{ newsDataset.notes | characters: 110 }}"></markdown>
                        <div [ngStyle]="{ opacity: newsDataset.notes.length > 10 ? '1' : '0' }"></div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                  <mat-card class="mat-elevation-z2 news-card-more" fxLayout="column" *ngIf="newsDatasets.length > 0">
                    <a href="getNewsRoute()">
                      More
                    </a>
                    <!-- <mat-card-header class="noMarginHeader mat-subheading-2">
                      <mat-card-title class="news-tab-title">

                      </mat-card-title>
                    </mat-card-header> -->
                  </mat-card>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
    </div>
  </div>
</div>

<!-- The components will be loaded underneath the app-home component Therefore any css that should effect it, like the fxFlex should added to the external home component-->