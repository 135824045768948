<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <h1>
      {{ 'DATAPLATFORM.TERMS' | translate }}
      <hr>
    </h1>
    <br>
    <div>
      <div class="field-items" [ngSwitch]="language">
        <div *ngSwitchCase="'nl'"><ng-container *ngTemplateOutlet="dutch"></ng-container></div>
        <div *ngSwitchCase="'en'"><ng-container *ngTemplateOutlet="english"></ng-container></div>
      </div>
    </div>
    <br><br>
  </div>
</div>

<!-- templates -->

<ng-template #dutch>
  <p>De gegevens op Dataplatform zijn door iedereen vrij te gebruiken, tenzij de licentievoorwaarden anders vermelden. Bij elke dataset zijn de licentievoorwaarden vermeld. De aanbieder c.q. eigenaar van de data bepaalt de licentievoorwaarden. Dataplatform neemt die over.</p>
  <p>De eigenaar van Dataplatform en haar deelnemers:</p>
  <ul>
    <li>Zijn gerechtigd te publiceren over toepassingen en services die op basis van open data zijn ontwikkeld, zonder toestemming van ontwikkelaars en/of gebruikers;</li>
    <li>Verwelkomen (door)verwijzingen naar Dataplatform, maar verbieden de verspreiding van onjuiste of misleidende informatie;</li>
    <li>Doen alles binnen hun mogelijkheden om&nbsp;data(sets) tijdig, correct en volledig beschikbaar te stellen. Data kunnen door de deelnemers c.q. aanbieders echter verzameld en samengesteld zijn&nbsp;voor andere doeleinden dan door de gebruiker gewenst. Onjuistheden in de data kunnen <a [routerLink]="getContactRoute()">online </a>worden doorgegeven;</li>
    <li>Aanvaarden geen enkele aansprakelijkheid voor beslissingen gebaseerd op de beschikbaar gestelde datasets, tenzij hier wederzijdse afspraken over zijn gemaakt die schriftelijk zijn vastgelegd;</li>
    <li>Behouden zich het recht voor zonder aankondiging vooraf datasets te verwijderen of aan te passen, tenzij schriftelijk anders overeengekomen is.</li>
  </ul>
  <p>Gebruikers die data&nbsp;van Dataplatform&nbsp;toepassen in applicaties en/of diensten mogen niet&nbsp;– impliciet of expliciet – aangeven dat hun applicatie en/of dienst de goedkeuring of steun heeft van (deelnemers aan) Dataplatform, tenzij schriftelijk anders overeengekomen is.</p>
  <p>Dataplatform nodigt iedereen uit te helpen&nbsp;om Dataplatform beter en waardevoller te maken:</p>
  <ul>
    <li>Aanbieders van toepassingen en diensten die gebruik maken van Dataplatform&nbsp;wordt gevraagd actief te communiceren over Dataplatform en hun <a href="//www.dataplatform.nl/contact/showcase-aanmelden">voorbeeld </a>te melden;</li>
    <li>Gebruikers en aanbieders van data worden gevraagd om <a [routerLink]="getContactRoute()">onjuistheden </a>in data(sets) door te geven;</li>
    <li>Belangstellenden wordt&nbsp;gevraagd <a [routerLink]="getContactRoute()">wensen en suggesties</a> voor Dataplatform, de datasets en toepassingen door te geven.</li>
  </ul>
</ng-template>

<ng-template #english>
    <p>The data on Dataplatform can be freely used by anyone, unless the license conditions state otherwise. For each dataset, the license conditions are mentioned. The provider or owner of the data determines the license conditions. The data platform takes over these terms and conditions.</p>
    <p>The owner of Dataplatform and its participants:</p>
    <ul>
        <li>Are entitled to publish about applications and services that are developed on the basis of open data, without permission of developers and/or users;</li>
        <li>Welcome (onward) references to the Data Platform, but forbid the dissemination of incorrect or misleading information;</li>
        <li>Do everything in their power to make data (sets) available in a timely, correct and complete manner. However, data may have been collected and compiled by participants or providers for purposes other than those desired by the user. Inaccuracies in the data can be passed on <a [routerLink]="getContactRoute()">online</a>;</li>
        <li>Aanvaarden geen enkele aansprakelijkheid voor beslissingen gebaseerd op de beschikbaar gestelde
        <li>Do not accept any liability for decisions based on the datasets made available, unless mutual agreements have been made about this in writing;</li>
        <li>Reserve the right to remove or modify datasets without prior notice, unless otherwise agreed in writing.</li>
      </ul>
      <p>Users who use data from Dataplatform in applications and/or services may not - implicitly or explicitly - indicate that their application and/or service has the approval or support of (participants in) Dataplatform, unless otherwise agreed in writing.</p>
      <p>Dataplatform invites everyone to help make Dataplatform better and more valuable:</p>
      <ul>
        <li>Providers of applications and services that use the Dataplatform are asked to actively communicate about Dataplatform and to report their <a href="//www.dataplatform.nl/contact/showcase-aanmelden">example</a>;</li>
        <li>Users and providers of data are asked to report <a [routerLink]="getContactRoute()">inaccuracies</a> in data (sets);</li>
        <li>Interested parties are asked to pass on their <a [routerLink]="getContactRoute()">wishes and suggestions</a> for Dataplatform, the datasets and applications.</li>
      </ul>
</ng-template>