import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';

@Injectable()
export class DataplatformContactService {

  constructor(private apiService: ApiService) {
  }

  sendFeedback(feedback: any) {
    return this.apiService.postFeedback(feedback);
  }
}
