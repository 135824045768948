import { MatDialog } from "@angular/material/dialog";
import { GISViewerAuthenticationDialog } from "../custom-components/gisviewer-authentication-dialog/gis-viewer-authentication-dialog.component";

export function openDialog(municipality: string, dialog:MatDialog):void {
    switch(municipality){
        case 'Exposome': {
            dialog.open(GISViewerAuthenticationDialog, {
                width: '750px',
            });
            break;
        }
    }
}