            <div fxLayout="column" fxFlex=60>
                <br />
                <h1 class="mat-headline" style="border-bottom: 1px solid #007487;">Almere Open Data</h1>
                <p style="text-align: justify;">
                    Welkom op het open data portaal van de gemeente Almere.
                    Op deze website vindt u een overzicht van de beschikbare open data van de gemeente Almere.
                </p>
                <p style="text-align: justify;">
                    Open data is data die vrij beschikbaar wordt gesteld en waar geen restricties op rusten.
                    In het kader van transparantie en innovatie wordt deze beschikbaar gesteld.
                </p>
                <p class="mat-h2">Open data is:</p>
                <ul>
                    <li>
                        <p>onbewerkte overheidsinformatie;</p>
                    </li>
                    <li>
                        <p>openbare data;</p>
                    </li>
                    <li>
                        <p>data zonder auteursrechten of rechten van derden;</p>
                    </li>
                    <li>
                        <p>bekostigd uit publieke middelen;</p>
                    </li>
                    <li>
                        <p>data die beschikbaar is gesteld voor de uitvoering van een publieke taak;</p>
                    </li>
                    <li>
                        <p>bij voorkeur 'open standaard'; dat wil zeggen zonder barrières voor gebruik ICT-
                            toepassingen,
                        </p>
                    </li>
                    <li>
                        <p>bij voorkeur leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</p>
                    </li>
                </ul>
                <p style="text-align: justify;">
                    Voor de gemeente Almere geldt dat het niet alleen om overheidsinformatie hoeft te gaan.
                    Ook (Almerese) data van partners kan in dit open data portaal worden opgenomen.
                </p>
                <p class="mat-h2">Gebruiksvoorwaarden</p>
                <p style="text-align: justify;">
                    Iedereen - particulier en bedrijf - mag de als zodanig aangemerkte open data van de gemeente Almere,
                    zoals geplaatst op Almere.dataplatform.nl hergebruiken in eigen toepassingen.
                </p>
                <br>
            </div>
            <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
            <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
                <br />
                <div class="policy-custom">
                    <h1 class="mat-headline" style="word-break: break-word">Open data en privacy</h1>
                    <p style="text-align: left;">
                        De gemeente Almere gaat zorgvuldig om met de gegevens van haar inwoners en houdt
                        zich aan de Algemene Verordening Gegevensbescherming (AVG). Bij open data gaat het
                        alleen om informatie die openbaar gemaakt mag worden, dus geen persoonlijke, vertrouwelijke
                        of bedrijfsgevoelige gegevens. Dus geen gegevens over personen. Informatie die schade kan
                        veroorzaken of de veiligheid in gevaar kan brengen wordt ook niet via open data verspreid. <br>
                        <br>
                </div>
            </div>