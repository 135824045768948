import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnInit {

  constructor(private navigationService:NavigationService) { }

  ngOnInit() {
    this.navigationService.toHome();
  }

}
