import { Component, OnInit, Input } from '@angular/core';
import { OrganizationModel } from '@shared/models/organization.model';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-organization-card-malmo',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class OrganizationCardComponentMalmo implements OnInit {

  @Input() organization: OrganizationModel;
  @Input() index: number;
  mouseOver: boolean;

  constructor(private navigationService:NavigationService) {
  }

  ngOnInit() {
  }

  addQueryParam(organizationName) {
    this.navigationService.toData({queryParams: {['organization.name']: organizationName}, queryParamsHandling: 'merge'});
  }
}
