import { Component, OnInit } from '@angular/core';
import { OrganizationService } from './organization.service';
import { MatomoTracker } from 'ngx-matomo';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { OrganizationModel } from '@shared/models/organization.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationProvincieNoordHollandComponent implements OnInit {

  private organizations: OrganizationModel[] = [];
  private showLoading: boolean;

  constructor(
    private organizationService: OrganizationService, 
    private matomoTracker: MatomoTracker,
    private dynamicInjectorService: DynamicInjectorService) {
    this.showLoading = true;
  }
  ngOnInit() {
    this.matomoTracker.setDocumentTitle('Organizations');
    this.organizationService.getOrganizations().then(organizations => {
      organizations.forEach(o => {
        const organization: OrganizationModel = o;
        this.organizations.push(organization);
      });
      if (this.dynamicInjectorService.getEnvironment().name === 'Provincie-Noord-Holland') {
        this.organizations.sort((a, b) => b.package_count - a.package_count);
      }
       this.showLoading = false;
    });
  }

  public getOrganizations():OrganizationModel[]{
    return this.organizations;
  }

  public isShowLoading():boolean{
    return this.showLoading;
  }
}
