<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
    <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">

      <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61"
        fxLayoutAlign="space-between">

        <div fxFlex="20">
          <p class="mat-subheading-1">{{'FOOTER.VISIT_ADDRESS' | translate}}</p>
</div>

        <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">{{'FOOTER.CONTACT' | translate}}</p>
            <p class="mat-body-1">

                (023) 514 31 43<br>
              <a href="https://www.noord-holland.nl/Loket/Contact/Contact" target="_blank">https://www.noord-holland.nl/Loket/Contact/Contact</a><br>
            </p>
          </div>
        </div>


        <p>Powered by <a href="https://www.dataplatform.nl/"
            target="_blank">dataplatform.nl</a><br></p>
        <br>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

