<ng-container class="carousel-widget" fxLayout="row" *ngIf="showCarousel(); else NO_CAROUSEL">
  <swiper [pagination]="{clickable:true}" [autoplay]="getCarouselAutoplay()" [ngStyle]="getCarouselHeight()" [navigation]="true" class="swiper mat-primary">
      <ng-template swiperSlide *ngFor="let dataset of getCarouselDatasets()">
        <div class="swiper-details" [style.background-image]="'url(' + dataset.carousel_image + ')'">
          <h1 id="carousel_title">{{dataset.carousel_text }}</h1>
          <div id="carousel_redirect">
            <button mat-raised-button (click)="goToDatasetDetail(dataset.id)" class="slider-more-info-button">{{ 'HOMEPAGE.GO_TO_DATA' | translate}}</button>
          </div>
        </div>
      </ng-template>
  </swiper>
</ng-container>
<ng-template #NO_CAROUSEL>
        <div fxFlex="100" fxLayoutAlign="start">
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="100" fxLayoutAlign="center">
            <img class="logo-image" *ngIf="hasLogoImage()" [src]="getLogoImageSrc()" [alt]="getLogoImageAltText()"/>
            <br>
            <img class="banner-image" *ngIf="hasBannerImage()" [src]="getBannerImageSrc()" [alt]="getBannerImageAltText()"/>
          </div>
        </div>
</ng-template>
