      <div fxLayout="column">
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">About us</h1>
        <p style="text-align: justify;">
          SCOREwater (H2020 Innovation Project) focuses on enhancing the resilience of cities against climate change and
          urbanization by enabling a water smart society that fulfils the Sustainable Development Goals 3, 6, 11, 12 and
          13 and secures future ecosystem services.
        </p>
        <p style="text-align: justify;">
          SCOREwater develops and tests three large-scale demonstrations cases for collecting, computing and presenting
          various data tailored to needs of our stakeholders. In Barcelona we initiate a new domain “sewage sociology”
          mining biomarkers of community-wide lifestyle habits from sewage. In Amersfoort we develop new water
          monitoring techniques and data-adaptive storm water treatment and apply to water resource protection and legal
          compliance for construction projects within the Göteborg-case. We enhance resilience against flooding by
          sensing and hydrological modelling coupled to urban water engineering. We will identify best practices for
          developing and using the digital services, thus addressing water stakeholders beyond the project partners. The
          project will also develop technologies to increase public engagement in water management. Moreover, SCOREwater
          will deliver an innovation ecosystem driven by the financial savings in both maintenance and operation of
          water systems that are offered using the SCOREwater digital services, providing new business opportunities for
          water and ICT SMEs.<br>
          <br>
          <a href="https://www.scorewater.eu/about-us">Read about our mission, vision and uniqueness</a>
        </p>
      </div>