            <div fxLayout="column" fxFlex=60>
                <p>
                    Hoewel Nederland een echt fietsland is, is er een groot verschil in beschikbaarheid van kennis,
                    gegevens en
                    informatie over fietsen in vergelijking met bijvoorbeeld de auto. Hierdoor blijft fietsen vaak
                    onderbelicht
                    in het algemene mobiliteitsbeleid. Gelukkig is er de laatste jaren een groeiende aandacht voor de
                    fiets.
                    Terecht, want de fietser en de fiets kunnen bijdragen aan het oplossen van verschillende
                    maatschappelijke
                    problemen. Het gaat dan niet alleen om verkeersproblematiek maar ook om luchtkwaliteit,
                    volksgezondheid,
                    interactie/participatie en de geluksbeleving.
                </p>
                <p>
                    Voor veel mensen kan de fiets een goed alternatief zijn waar men persoonlijk voordeel van kan
                    hebben.
                    Bovendien komen er nieuwe technologieën op de markt waardoor het mobiliteitssysteem in Nederland
                    verandert.
                    De opkomst van de elektrische fiets en het gebruik van media op de fiets zorgt voor een verandering
                    in het
                    fietsen en verkeersveiligheid. Daarnaast komen er steeds meer methoden beschikbaar om het fietsen te
                    vergemakkelijken, te versnellen en metingen uit te voeren. De provincie Utrecht wil een koploper
                    zijn op het
                    gebied van deze fietskennis en een bijdrage leveren aan de ontwikkeling van fietsend Nederland.
                </p>
                <p>
                    Met deze doelstelling is de provincie Utrecht experiment Snuffelfiets gestart. In dit experiment
                    wordt op
                    grote schaal mobiele data op de fiets verzameld door participerende burgers. IoT bedrijf SODAQ is
                    als
                    partner aangesloten voor de productie van de mobiele sensoren. Partner Civity is verantwoordelijk
                    voor het
                    datamanagement en RIVM ondersteunt bij het valideren van de verzamelde data.
                </p>
            </div>
            <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
            <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
                <br />
                <div class="policy-custom">
                    <h2 class="mat-headline ">Snuffelfiets</h2>
                    <p style="text-align: justify;">Snuffelfiets is een samenwerking tussen de Civity, SODAQ, RIVM en
                        verschillende gemeentes en provincies. Civity is specialist in data-oplossingen, SODAQ is expert
                        in het
                        maken van sensoren en RIVM is verantwoordelijk voor datavalidatie.
                    </p>

                    <h2></h2>
                </div>
            </div>