import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatasetsService } from '@shared/services/datasets.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import { HeaderButtonModel } from '@shared/models/organization.config.model';
import { Router } from '@angular/router';
import { KeycloakUserService } from '@shared/services/keycloak-user.service';
import { openDialog } from '../../../../portal-config/custom/custom-functions/custom.dialog.functions';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})

export class AppHeaderComponent {

  expanded = false;
  activeLanguageCode: string = 'EN';
  private languages: Map<string, string> = new Map<string, string>();

  constructor(
    private router: Router,
    
    public dialog: MatDialog, 
    private datasetsService: DatasetsService,
    private translateService: TranslateService,
    private dynamicInjectorService: DynamicInjectorService,
    private navigationService: NavigationService,
    private keycloakUserService: KeycloakUserService
  ) {
    this.checkForeignCustomer();
  }

  public changeTranslation(value: string): void {
    if (!_.isUndefined(value)) {
      this.setTranslationLanguage(value);
      localStorage.setItem('selectedLanguage', value);
      this.activeLanguageCode = value.toUpperCase()
    } else {
      this.setTranslationLanguage('en');
    }
  }

  /**
   * Changes the page language for translable text fields as well as for accessibility
   * @param lang the language you want to use
   * @todo the same function exists in app.component.ts, we might want to share this in a service
   */
  private setTranslationLanguage(lang:string) : void{
    this.translateService.use(lang);
    if(document.getElementsByTagName('html') && document.getElementsByTagName('html')[0] && document.getElementsByTagName('html')[0].getAttribute('lang')){
      document.getElementsByTagName('html')[0].setAttribute('lang',lang);
      console.debug(`Changed page language to: ${document.getElementsByTagName('html')[0].getAttribute('lang')}.`);
    }
  }

  private checkForeignCustomer(): void {
    this.dynamicInjectorService.getOrganizationConfig().supportedLanguages.forEach(language => {
      this.languages.set(language, this.dynamicInjectorService.getSettingsConfig().languages.get(language));
    })
  }

  redirectExternal(url: string) {
    document.location.href = url;
  }

  redirectBlankExternal(url: string) {
    let w = window.open(url, "_blank");
    w.document.close();
  }

  public getLanguagesKeys(): string[] {
    return Array.from(this.languages.keys());
  }

  public getLanguageName(key: string): string {
    return this.languages.get(key);
  }

  public hasMoreLanguages(): boolean {
    return this.languages.size > 1;
  }

  public showShoppingCart(): boolean {
    return this.dynamicInjectorService.getOrganizationConfig().header.showShoppingCart;
  }

  public getCustomerName(): string {
    return this.dynamicInjectorService.getEnvironment().name;
  }

  public getHeaderButtons(): HeaderButtonModel[] {
    return this.dynamicInjectorService.getOrganizationConfig().header.buttons;
  }

  public isHeaderButtonLink(headerButton: HeaderButtonModel): boolean {
    return headerButton.redirect == 'link';
  }

  public isHeaderButtonRoute(headerButton: HeaderButtonModel): boolean {
    return headerButton.redirect == 'route';
  }

  public getToolbarStyle():{[key: string]: any}{
    return this.dynamicInjectorService.getOrganizationConfig().header.style;
  }

  public isKeycloakEnabled(): boolean {
    return this.dynamicInjectorService.getOrganizationConfig().keycloakEnabled;
  }

  public isAuthorized(): boolean {
    return this.keycloakUserService.isAuthorized();
  }

  public login(): void {
    this.keycloakUserService.login();
    this.router.navigate([this.navigationService.getLastPath()])
  }

  public logout(): void {
    this.keycloakUserService.logout();
  }

  public getUsername(): string {
    return this.keycloakUserService.getUsername();
  }

  public getFullName(): string {
    return this.keycloakUserService.getFullName();
  }

  public getDialogName(): string {
    if (this.dynamicInjectorService.getOrganizationConfig().auth){
      return this.dynamicInjectorService.getOrganizationConfig().auth.userMenu.dialogName
    }
  }

  public openDialog(): void {
    openDialog(this.dynamicInjectorService.getOrganizationConfig().name,this.dialog);
  }
  
  displayLanguageIcon(): boolean {
    const languageSelectorType = this.dynamicInjectorService.getOrganizationConfig().header.languageSelector.type
    if (languageSelectorType === undefined){
      return true
    }
    switch(languageSelectorType) { 
      case 'icon': { 
        return true
      } 
      case 'code': { 
        return false
      } 
      default: { 
        return true
      }
    } 
  }

  public getBuildVersion():string{
    return `${this.dynamicInjectorService.getEnvironment().version}`
  }

  public getBuildVersionFull():string{
    return `app version ${this.dynamicInjectorService.getEnvironment().version} and config version ${this.dynamicInjectorService.getSettingsConfig().version}`
  }

  public isWishListEmpty(): boolean {
    return this.datasetsService.isWishListEmpty()
  }

}
