<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
    <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
      <div class="content"
           fxLayout="row wrap"
           fxLayout.lt-md="column"
           fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

        <div fxFlex="20">
          <p class="mat-subheading-1">Bezoekadres</p>
          <p class="mat-body-1">
            Oldehoofsterkerkhof 2<br>
            8911 DH Leeuwarden<br>
          </p>

        </div>
        <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">Postadres</p>
            <!-- <p class="mat-body-1">
              Postbus 16200<br>
              3500 CE Utrecht<br>
            </p> -->
          </div>
        </div>
        <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">Contact</p>
            <p class="mat-body-1">
              Telefoon 14 058<br>
              <!-- WhatsApp 06 - 38 14 67 58<br> -->
              <a href="https://www.leeuwarden.nl/nl" target="_blank">https://www.leeuwarden.nl/nl</a><br>
            </p>
          </div>
        </div>

        <p>Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a><br></p> <br> <br>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
