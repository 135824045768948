import { Injectable } from '@angular/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { ApiService } from '@shared/services/api.service';
import { OrganizationModel } from '@shared/models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  ckanUrl: string;

  constructor(private apiService: ApiService, private dynamicInjectorService: DynamicInjectorService) {
      this.ckanUrl = this.dynamicInjectorService.getEnvironment().apiUrl;
  }

  getOrganizations(): Promise<OrganizationModel[]> {
    return new Promise<OrganizationModel[]>((resolve) => {
      this.apiService.getOrganizations().then(response => {
        const organizations: OrganizationModel[] = [];
        response.forEach(name => {
          if (name === 'civity') {
            return;
          } else {
            this.getOrganizationDetails(name).then(details => organizations.push(details));
          }
        });
        resolve(organizations);
      });
    });
  }

  getOrganizationDetails(name: string): Promise<OrganizationModel> {
    return this.apiService.getOrganizationByName(name);
  }
}
