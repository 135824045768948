      <div fxLayout="column">
        <br />
        <p class="mat-headline">Open Data</p>
        <hr>
        <p style="text-align: justify;">
        </p>
        <br>
        <h2 style="text-align:center"><strong><span style="color:#cc0000">Totaal aantal datasets :
              {{totalNoOfDatasets}}</span></strong></h2>
        <p style="text-align:center">({{currentDate | date :'dd MMMM yyyy' | translate}})</p>
        <br>
        <p class="mat-subheading-1"></p>
        <br>
        <p class="mat-subheading-1"></p>
        <p style="text-align: justify;"></p>

        <br>
        <br>
        <p class="mat-subheading-1"></p>
        <p style="text-align: justify;"></p>

        <br>
        <p><b>Proclaimer</b></p>
        <a href="" target="_blank"></a>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
      </div>