<div fxLayoutAlign="center">
    <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90 style="text-align: justify;" class="dataset-detail-min-height">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="35px">
        <div fxLayout="column" fxFlex=100>
            <div fxLayoutAlign="center center">
                <iframe frameborder="0"></iframe>
            </div>
        </div>
      </div>
    </div>
  </div>

