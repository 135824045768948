import { Component, OnInit, Input } from '@angular/core';
import { GroupModel } from '@shared/models/group.model';
import { TranslateService } from '@ngx-translate/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit {

  @Input() group: GroupModel;
  @Input() index: number;
  mouseOver: boolean;
  customerName: string;
  isOneCkanclient: Boolean = false;

  constructor(private navigationService: NavigationService, 
    private translateService: TranslateService, 
    private dynamicInjectorService: DynamicInjectorService) {
     this.customerName = this.dynamicInjectorService.getEnvironment().name;
     if (this.dynamicInjectorService.getOrganizationConfig().oneCkanClient) {
      this.isOneCkanclient = true
    }else{
      this.isOneCkanclient = false
    }
  }

  ngOnInit() {
    const isDutch: boolean = this.dynamicInjectorService.getOrganizationConfig().startLanguage == 'nl';
    const isSwedish: boolean = this.dynamicInjectorService.getOrganizationConfig().startLanguage == 'sv';

    if (isSwedish) {
      this.translateService.get('THEME_SWEDISH.' + this.group.display_name).subscribe(value => {
        this.group.translatedName = value;
      });
    } else if (isDutch) {
      this.translateService.get('THEME_DUTCH.' + this.group.display_name).subscribe(value => {
        this.group.translatedName = value;
      });
    }

    this.translateService.onLangChange.subscribe(() => {
      if (isDutch) {
        this.translateService.get('THEME_DUTCH.' + this.group.display_name).subscribe(value => {
          this.group.translatedName = value;
        });
      } else if (isSwedish) {
        this.translateService.get('THEME_SWEDISH.' + this.group.display_name).subscribe(value => {
          this.group.translatedName = value;
        });
      }
    });
  }

  public addQueryParam(groupName:string):void {
    let groupQueryString : string;
    if (this.isOneCkanclient == true){
      groupQueryString = 'groups.title';
    }else{
      groupQueryString = 'theme';
    }

    this.navigationService.toData({ queryParams: { [`${groupQueryString}`]: groupName }, queryParamsHandling: 'merge' });
  }

  public hasDisplayImage(group: GroupModel):boolean{
    return group.image_display_url != undefined && group.image_display_url != '';
  }


}
