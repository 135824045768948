import { Component, OnInit, Inject, HostListener, Host } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatomoTracker } from 'ngx-matomo';


@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {

  windowScrolled: boolean;

  constructor(@Inject(DOCUMENT) private document: Document, private matomoTracker: MatomoTracker) { }

  @HostListener('window:scroll', [])

  onWindowScroll() {
    if (window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  executeToTop() {
    this.matomoTracker.trackEvent('button clicked', 'scroll to top', 'scroll to top');
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }

    })();
  }



  ngOnInit() {
  }

}
