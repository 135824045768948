      <div fxLayout="column" fxFlex=100>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #0571b3;">Lidingö stads öppna data</h1>
        <p>
          Öppna data är information som görs tillgänglig utan avgift eller begränsande villkor. På de här sidorna
          publicerar
          staden data som är fri för vidareutnyttjande.
          <br>
          <br>
          Tanken är att offentlig information inte bara ska användas av myndigheter utan även av till exempel företag
          och
          allmänhet för att skapa nya tjänster.
          <br>
          <br>
          Informationen kommer från olika förvaltningar inom kommunen.
          <br>
          Har du frågor om en datamängd som publiceras här kan du kontakta oss genom att skicka en e-post till 
          <a href="mailto:kundcenter@lidingo.se" target="_blank"> kundcenter@lidingo.se</a>, använda e-tjänsten <a href="https://www.eservice.lidingo.se/EServices/Common/FieldPage.aspx?eServiceName=SYNPUNKTER&PageId=Contact" target="_blank">Kontakta oss / synpunkter</a>, eller ringa till Kundservice på 08-731 30 00. Det underlättar om du kan inkludera datamängdens kontaktperson, det finns i varje datamängds metadata.
          <br>
          <br>
          Vad som är öppna data regleras i PSI- och Inspire-direktiven från EU som också införts i svensk lagstiftning.
          <br>
          <br>
          Läs mer om öppna data:
          <br>
        </p>
        <ul>
          <li>
            <a href="https://skr.se/naringslivarbetedigitalisering/digitalisering/informationsforsorjningdigitalinfrastruktur/sklsoppnadata.psidata.html"
              target="_blank"> Öppna data från SKR, SKR:s webbplats Länk till annan webbplats. </a>
          </li>
          <li>
            <a href="https://sv.wikipedia.org/wiki/%C3%96ppna_data" target="_blank"> Öppna data på wikipedia, Wikipedias
              webbplats Länk till annan webbplats. </a>
          </li>
          <li>
            <a href="https://www.digg.se/kunskap-och-stod/oppna-och-delade-data/offentliga-aktorer/information-om-oppna-datalagen/" 
              target="_blank"> Öppna data och PSI, Myndigheten för Digital förvaltning Länk till annan webbplats. </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=iUlwJVhsHSY" target="_blank"> Nya möjligheter med öppna data,
              Naturvårdsverkets YouTube Länk till annan webbplats. </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=UG8IWHr4J7I" target="_blank"> Samhällsnyttan genom öppna data,
              Naturvårdsverkets YouTube Länk till annan webbplats. </a>
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=wSWDcrX-K0Q" target="_blank"> Collective Intelligence via Open
              Data, Naturvårdsverkets YouTube Länk till annan webbplats. </a>
          </li>
        </ul>
      </div>