<div class="footer" fxLayout fxLayoutAlign="center">
   <div fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=80>
     <br>
     <div class="footer-content" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.xs="column">
       <!-- FIRST COLUMN BRAND -->
       <div fxLayout="column"  fxFlex="50" fxLayoutAlign="start">
           <div>
               
               <img src="https://www.health-ri.nl/themes/custom/health_ri/assets/images/logo-footer.svg" style="width: 50%; height: auto;">
               <!-- <p class="mat-subheading-1"> 
                 Health-RI is the Dutch national initiative to facilitate and stimulate an integrated health data infrastructure 
                 accessible for researchers, citizens, care providers and industry. It will enable optimal use of health data, 
                 samples and images, a learning healthcare system and accelerate personalized health. 
               </p> -->
             </div>
       </div>
       <!-- SECOND COLUMN CONTACT -->
       <div fxLayout="column"  fxFlex="25" fxLayoutAlign="start">
         <ul region="footer_navigation" class="menu menu-level-0">
           <li class="menu-item">
             <a href="https://www.health-ri.nl/health-ri-service-desk" target="_blank">Health-RI Service Desk</a>
           </li>
           <li class="menu-item">
             <a href="mailto:datacatalogue@health-ri.nl">Contact</a>
           </li>
         </ul>
      </div>
      <!-- THIRD COLUMN SOCIAL -->
      <div fxLayout="column"  fxFlex="25" fxLayoutAlign="start">
        <ul class="social-follow">
          <li class="social-follow__twitter">
             <a href="https://twitter.com/health__ri" target="_blank" rel="noopener noreferrer">
                <div class="icon">
                   <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" viewBox="8 8 32 32">
                      <path fill="currentColor" d="M30.906 20.256c.01.142.01.283.01.424 0 4.315-3.437 9.287-9.72 9.287a9.981 9.981 0 0 1-5.246-1.466c.275.03.54.04.825.04a7.041 7.041 0 0 0 4.241-1.395c-1.502-.03-2.761-.97-3.194-2.263a3.803 3.803 0 0 0 1.544-.06c-1.565-.304-2.74-1.617-2.74-3.204v-.039a3.57 3.57 0 0 0 1.544.414c-.92-.586-1.523-1.586-1.523-2.718 0-.606.169-1.162.465-1.647 1.682 1.98 4.209 3.274 7.044 3.416a3.522 3.522 0 0 1-.085-.748c0-1.799 1.524-3.264 3.417-3.264.984 0 1.872.395 2.496 1.031a6.924 6.924 0 0 0 2.168-.789 3.288 3.288 0 0 1-1.501 1.799 7.123 7.123 0 0 0 1.967-.505 7.182 7.182 0 0 1-1.712 1.687z"></path>
                      <path fill="none" stroke="currentColor" d="M12.165 10.165h22.67a2 2 0 0 1 2 2v22.67a2 2 0 0 1-2 2h-22.67a2 2 0 0 1-2-2v-22.67a2 2 0 0 1 2-2z"></path>
                   </svg>
                </div>
                <span>Twitter</span>
             </a>
          </li>
          <li class="social-follow__linkedin">
             <a href="https://www.linkedin.com/company/health-ri" target="_blank" rel="noopener noreferrer">
                <div class="icon">
                   <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" viewBox="8 8 32 32">
                      <path fill="currentColor" d="M19.743 30.001h-2.696V21.32h2.696v8.681zm-1.349-9.865c-.862 0-1.562-.714-1.562-1.576a1.562 1.562 0 0 1 3.123 0c0 .862-.699 1.576-1.561 1.576zm11.44 9.865h-2.69v-4.226c0-1.007-.021-2.298-1.401-2.298-1.402 0-1.616 1.093-1.616 2.226v4.298h-2.694V21.32h2.586v1.184h.037c.359-.682 1.239-1.401 2.552-1.401 2.728 0 3.229 1.796 3.229 4.129v4.769h-.003z"></path>
                      <path fill="none" stroke="currentColor" d="M12.165 10.165h22.67a2 2 0 0 1 2 2v22.67a2 2 0 0 1-2 2h-22.67a2 2 0 0 1-2-2v-22.67a2 2 0 0 1 2-2z"></path>
                   </svg>
                </div>
                <span>LinkedIn</span>
             </a>
          </li>
          <li class="social-follow__other">
             <a href="https://www.youtube.com/channel/UCOnAoZWtUjuDZE8ZjnYeBtA" target="_blank" rel="noopener noreferrer">
                <div class="icon">
                   <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48">
                      <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(4 4)">
                         <polygon points="32 20 14 29.8 14 10"></polygon>
                         <circle cx="20" cy="20" r="20"></circle>
                      </g>
                   </svg>
                </div>
                <span>Youtube</span>
             </a>
          </li>
         </ul>
      </div>
    </div>
  </div>
</div>
