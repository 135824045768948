import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatasetModel } from '@shared/models/dataset.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '@shared/services/notification.service';
import { DataplatformContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() datasetDetails: DatasetModel;
  replyForm: FormGroup;
  responseMessage: string;
  typeOfRespDummy = ['Algemene vraag over dataset',
    'Probleem met of fout in dataset',
    'Showcase met dataset',
    'Suggesties voor verbetering/uibreiding dataset',
    'Anders (gebruik de toelichting om uw reactie te beschrijven)'];

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router, private dataplatformContactService: DataplatformContactService,
   private notificationService: NotificationService) {
  }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.replyForm = this.formBuilder.group({
      dataset_id: [''],
      dataset_name: [''],
      request_type: [this.typeOfRespDummy[0]],
      message: [''],
      name: [userData ? userData.name : '', Validators.required],
      email: [userData ? userData.email : '', [Validators.required,
        Validators.pattern(/^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/)]],
      phone_number: [userData ? userData.phone_number : '', Validators.pattern(/^(?:-?\d-?){10}$/)]
    });
  }

  onSubmit() {
    if (this.replyForm.valid) {
      const userData = {
        name: this.replyForm.get('name').value,
        email: this.replyForm.get('email').value,
        phone_number: this.replyForm.get('phone_number').value
      };
      localStorage.setItem('userData', JSON.stringify(userData));

      this.dataplatformContactService.sendFeedback(userData).then((response: any) => {
        this.notificationService.sendNotification(response, '');
      });
    }

  }


}
