<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
        <div class="content"
             fxLayout="row wrap"
             fxLayout.lt-md="column"
             fxFlexFill fxFlex="61" fxLayoutAlign="space-between">
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
