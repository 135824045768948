<div class="footer" fxLayout fxLayoutAlign="center">
  <div  fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=80>
    <br>
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.xs="column">
      <div fxLayout="column"  fxFlex="33" fxLayoutAlign="start">
          <div>
              <p class="mat-subheading-1" style="font-weight: 600;font-size: 20px;"> {{'DATAPLATFORM.PRODUCT' | translate}} </p>
              <img [src]="getAsset('images/logo.png')" style="width: 60%; height: auto;">
            </div>
      </div>
      <div fxLayout="column"  fxFlex="33" fxLayoutAlign="start">
          <div>
              <p class="mat-subheading-1" style="font-weight: 600; font-size: 20px;">Contact:</p>
              <div>
                <div class="roundBorder roundBorderIeCompat">
                    <button mat-button routerLink="/custom/contact">
                       {{'DATAPLATFORM.CONTACT' | translate}} &nbsp;&nbsp;&nbsp;&nbsp;<mat-icon class="roundBorderIcon">phone</mat-icon>&nbsp;
                      </button>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayout.xs="column" style="margin-top: 20px;">
                <div>
                  <p><a href="https://www.linkedin.com/groups/8448471" target="_blank"><i class="fa fa-linkedin" style="color: #008fc7; font-size: 30px; margin-right: 20px;"></i>
                    <!-- <span style="font-size: 15px">&nbsp;&nbsp;LinkedIn</span> -->
                  </a></p>
              </div>
              <div>
                  <p><a href="https://twitter.com/dataplatformnl" target="_blank"><i class="fa fa-twitter-square" style="color: #008fc7; font-size: 30px; margin-right: 20px;"> </i> 
                    <!-- <span style="font-size: 15px">&nbsp;&nbsp;Twitter</span> --> 
                </a></p>
              </div>
              <div>
                  <p><a href="http://www.slideshare.net/Dataplatform" target="_blank"><i class="fa fa-slideshare" style="color: #008fc7; font-size: 30px; margin-right: 20px;"></i>
                    <!-- <span style="font-size: 15px">&nbsp;&nbsp;SlideShare</span> --> 
                </a></p>
              </div>
              <div>
                  <p><a href="https://www.youtube.com/channel/UCkPX1GxJxVflQUOWorr0n6g" target="_blank"><i class="fa fa-youtube" style="color: #008fc7; font-size: 30px; margin-right: 20px;"></i>
                    <!-- <span style="font-size: 15px">&nbsp;&nbsp;Youtube</span> --> 
                  </a></p>
              </div>
              </div>
            </div>
      </div>
      <div class="info-links" fxLayout="column"  fxFlex="33" fxLayoutAlign="start">
          <div>
              <p class="mat-subheading-1" style="font-weight: 600; font-size: 20px;">Info:</p>
              <div>
                 <a class="button" routerLink="/custom/privacy" target="_blank">{{'DATAPLATFORM.PRIVACY' | translate}}</a>
               </div>
               <div>
                <a class="button"  routerLink="/custom/faq" target="_blank">{{'DATAPLATFORM.FAQ' | translate}}</a>
               </div>
               <div>
                  <a class="button" routerLink="/custom/terms-of-use" target="_blank">{{'DATAPLATFORM.TERMS' | translate}}</a>
               </div>
            </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column">
        <div fxLayout="column"  fxLayoutAlign="center center">
          <br>
            <p>{{'DATAPLATFORM.ALL_RIGHTS_RESERVED' | translate}}</p>
        </div>
    </div>
  </div>
