import { Component, OnInit, ElementRef } from '@angular/core';
import { DatasetsService } from '@shared/services/datasets.service';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';

@Component({
  selector: 'app-sandbox-home-text',
  templateUrl: './sandbox-home-text.component.html',
  styleUrls: ['./sandbox-home-text.component.scss']
})
export class SandboxHomeTextComponent implements OnInit, ConfigInterface {

  url;
  customIframe;
  currentDate: Date;
  totalNoOfDatasets: number;

  constructor(private elementRef: ElementRef, 
    private datasetsService: DatasetsService,
    private dynamicInjectorService: DynamicInjectorService,
    ) {
    this.currentDate = new Date();
  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {

    this.datasetsService.getTotalNumberOfDatasets().then((result: number) => {
      this.totalNoOfDatasets = result;
    });

  }

  getAsset(path) {
    return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${path}`
  }
}
