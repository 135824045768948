import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DynamicInjectorService } from './dynamic.injector.service';
import { NavigationService } from './navigation.service';

@Injectable()

export class AuthGuardService implements CanActivate {

  constructor(
    protected navigationService: NavigationService,
    private dynamicInjectorService: DynamicInjectorService
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (_.includes(route.data.customers, this.dynamicInjectorService.getEnvironment().name)) {
      return true;
    }
    this.navigationService.toStart();
    return false;
  }
}
