import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { forkJoin, Observable, Subject } from "rxjs";
import { OrganizationModel } from '@shared/models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(
    private apiService:ApiService,
    ) {
  }

  getOrganizations(): Observable<OrganizationModel[]> {
    const organizationSubject: Subject<OrganizationModel[]> = new Subject<OrganizationModel[]>();
    this.apiService.getOrganizations().then(response => {
      const organizationList: Promise<OrganizationModel>[] = [];
      const organizationNames: string[] = response;
      for (const organizationName of organizationNames) {
        if (organizationName !== 'civity') {
          organizationList.push(this.apiService.getOrganizationByName(organizationName));
        }
      }
      forkJoin(organizationList).subscribe(responseModels => organizationSubject.next(responseModels));
    });
    return organizationSubject.asObservable();
  }

}
