import { Component, OnInit } from '@angular/core';
import { Question } from './faq.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  questions: Array<Question>;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.getQuestionsFromTranslateService();
    this.translateService.onLangChange.subscribe(x => {
      this.getQuestionsFromTranslateService();
    });
  }

  // retrieve list of questions from the translation file
  getQuestionsFromTranslateService() {
    this.translateService.get('DATAPLATFORM.FAQ_QUESTIONS').subscribe((res: Array<Question>) => {
      this.questions = res;
    });
  }

}
