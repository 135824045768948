<div fxLayoutAlign="center" class="ieCompat">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <form [formGroup]="replyForm" (ngSubmit)="onSubmit()" fxFlex="100" fxLayoutAlign="center" fxLayout="column"
      fxLayoutGap="10px">

      <mat-form-field>
        <mat-select formControlName="request_type" placeholder="{{'REPLY.TYPE_OF_RESPONSE' | translate}}">
          <mat-option *ngFor="let typeOfResp of typeOfRespDummy" [value]="typeOfResp">
            {{typeOfResp}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <textarea rows="5" formControlName="message" matInput placeholder="{{'REPLY.COMMENTS' | translate}}"></textarea>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="{{'REPLY.NAME' | translate}}" formControlName="name" required>
        <mat-error *ngIf="replyForm.get('name').hasError('required')">{{'ERROR_MESSAGES.FIELD_REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="{{'REPLY.EMAIL' | translate}}" formControlName="email" required>
        <mat-error *ngIf="replyForm.get('email').hasError('required')">{{'ERROR_MESSAGES.FIELD_REQUIRED' | translate}}
        </mat-error>
        <mat-error *ngIf="replyForm.get('email').hasError('pattern')">{{'ERROR_MESSAGES.E_MAIL_INVALID' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="{{'REPLY.PHONE' | translate}}" formControlName="phone_number">
        <mat-error *ngIf="replyForm.get('phone_number').hasError('pattern')">{{'ERROR_MESSAGES.PHONE_INVALID' | translate}}
        </mat-error>
      </mat-form-field>

      <div fxLayoutAlign="start">
        <!-- <button mat-flat-button color="primary">{{'REPLY.SUBMIT' | translate}}</button> -->
          <div class="roundBorder roundBorderIeCompat">
            <button mat-button>
            Neem contact op&nbsp;&nbsp;&nbsp;<mat-icon class="roundBorderIcon">phone</mat-icon>&nbsp;
            </button>
          </div>
        <!--<mat-progress-spinner *ngIf="showLoading"></mat-progress-spinner>-->
        <mat-label>{{responseMessage}}</mat-label>
      </div>
    </form>
  </div>
</div>
<br><br><br><br><br>
