      <div fxLayout="column" fxFlex=60>
        <br />
        <h1 class="mat-headline" style="color:#d5a95c; border-bottom:1px solid #b3b2b2;"><strong>IoT Stadslab Den
            Bosch</strong></h1>
        <h2 class="mat-headline" style="color:#d5a95c; font-family: merriweather serif;">Welkom op het dataplatform van
          de
          IoT Stadslab Den Bosch</h2>
        <p style="text-align: justify;">
          Welkom op het open data platform! Op deze website vindt u een overzicht van de beschikbare
          open data. Als u ideeën heeft, verbeterpunten ziet of vragen wilt stellen, neem dan
          contact met ons op.
        </p>
        <br>
        <br>
        <p class="mat-subheading-1">Open data is:</p>
        <ul>
          <li>
            <p>openbare data,</p>
          </li>
          <li>
            <p>openbare data,</p>
          </li>
          <li>
            <p>bekostigd uit publieke middelen,</p>
          </li>
          <li>
            <p>verzameld voor werkzaamheden overheidsinstantie,</p>
          </li>
          <li>
            <p>liefst ‘open standaard’; dat wil zeggen zonder barrières voor gebruik ICT-toepassingen,</p>
          </li>
          <li>
            <p>liefst leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</p>
          </li>
        </ul>
        <br>
        <p class="mat-subheading-1">Waardevolle informatie</p>
        <p style="text-align: justify;">Pellentesque purus nisi, tristique a fermentum ut, egestas quis ligula.
          In hac habitasse platea dictumst. Nullam at feugiat lorem, eu fermentum lacus. Nam vitae ipsum eget ex
          efficitur
          ornare.
          Sed a ex id ante auctor vulputate eget eget erat. Aenean iaculis vitae eros at imperdiet. Mauris euismod neque
          egestas mauris elementum, vitae dapibus felis vehicula. Integer malesuada at nisi at bibendum. Sed mollis
          tincidunt
          arcu vel scelerisque. In a diam et arcu mattis cursus. Nullam semper, nunc porta commodo dictum, mi mauris
          iaculis
          quam,
          vitae congue ante felis quis arcu.</p>
        <br>
        <br>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <p class="mat-subheading-1">Open data en privacy</p>
          <p style="text-align: justify;">Donec congue tellus leo, vel cursus nulla ultrices vitae. Fusce sagittis
            eleifend
            mi, quis
            posuere lectus posuere in. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed varius lacus et
            sollicitudin tempor.
            Curabitur luctus nulla eu nisi aliquam commodo. Proin ut pretium nisi, vitae aliquet justo. Aliquam lacinia
            orci
            eu pulvinar vulputate.
            Suspendisse vel lobortis lectus. Suspendisse quis dolor erat. In aliquet ullamcorper mi at mollis. Donec
            vulputate id sapien malesuada porta.
            Nullam dignissim orci eget interdum vestibulum.
          </p>
          <br>
        </div>
      </div>