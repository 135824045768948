<div fxLayoutAlign="center">
    <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
      <div fxLayout="row">
        <div fxLayout="row" fxLayout.lt-md="column">
          <div  fxLayout="column" fxFlex=100 class="dataset-detail-min-height">
              <br/>
              <p class="mat-headline" style="border-bottom: 1px solid #b3b2b2;">Een open data platform voor alle Friese overheden</p>
              <p class="mat-subheading-1">Open uitnodiging</p>
              <p style="text-align: justify;">
                  Het Fries Open Data Platform heeft de Provincie Fryslân, de Gemeente Leeuwarden en het Fries historisch en letterkundig
                  centrum Tresoar als initiatoren. Iedere Friese gemeente en ook het Wetterskip is echter van harte uitgenodigd om deel te
                  nemen aan het Fries Open Data Platform! Neem <a href="mailto:werkgroep@open.frl">contact op met de Friese open data werkgroep</a> als u interesse heeft.
                  Deelnemende partijen zijn uitgenodigd om op operationeel niveau mee te werken aan uitbreiding van het aanbod, en het
                  zorgen voor onderlinge afstemming en ervaringsdeling, en tevens om op meer strategisch niveau te bepalen welke koers het
                  Fries Open Data Platform gaat varen.
                  <br>
              </p>
              <p class="mat-subheading-1">Samen is Friese data waardevoller</p>
              <p style="text-align: justify;">
                  Als Friese gemeente of andere overheidsinstelling meedoen aan het Fries Open Data Platform heeft voordelen:
              </p>
              <ul class="text-color">
                  <li>Eén vindplaats voor alle data over Friesland, zonder dat het nodig is dat al die data ook in het platform is ondergebracht</li>
                  <li>Eigen infrastructuur voor publiceren is niet nodig, maar kan wel</li>
                  <li>Naar al elders gepubliceerde data kan worden verwezen (geen duplicatie), en wordt zo wel in het Fries Open Data Platform vindbaar</li>
                  <li>Data die over een groter deel van Friesland beschikbaar is wordt daar bruikbaarder van, en dat vergroot de kans op waardevolle toepassingen</li>
                  <li>Gezamenlijke ontwikkeling van bruikbare werkwijzen t.a.v. publicatie, standaarden, en beheer</li>
                  <li>Grote vindbaarheid van de eigen data door automatische koppeling aan het nationaal open data register
                    <a href="https://data.overheid.nl" target="_blank">https://data.overheid.nl</a> en het pan-Europese open data portaal
                  </li>
                  <li>Geringe kosten. De Provincie Fryslân draagt de basiskosten, en alle andere deelnemers een veel geringer bedrag in de vorm van een jaarabonnement.</li>
                  <li>Geringe beheerslast. Iedere deelnemer beheert alleen het onderhoud aan de eigen gepubliceerde datasets. Het beheer van het platform is geregeld.</li>
                </ul>
                <br>
              <p class="mat-subheading-1">Doe ook mee</p>
              <p style="text-align: justify;">
                  Interesse om ook als Friese gemeente of andere overheidsinstelling deel te nemen aan het Fries Open Data Platform? U
                  bent van harte welkom.
                  <a href="mailto:werkgroep@open.frl">Neem contact met ons op, we horen graag van u</a>
              </p>
        </div>
       </div>
      </div>
    </div>
  </div>
