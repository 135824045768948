      <div fxLayout="column" fxFlex=65>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid">Leeuwarden Open Data</h1>
        <p style="text-align: justify;">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis elit ex. Aenean eu est tristique,
          venenatis massa vel, euismod orci. Sed id turpis pretium, vulputate lacus sed, ullamcorper sem.
          Duis at ipsum metus. Morbi id faucibus lectus, vel dignissim nisl. Aenean egestas tristique ipsum, vel
          scelerisque quam molestie nec. Sed euismod fermentum nibh, eu consectetur eros aliquam a. Sed bibendum
          velit in vehicula semper. Aliquam odio elit, tristique id maximus a, sodales congue elit. Curabitur eleifend
          lacinia mauris sit amet lacinia.
        </p>
        <br>
        <p class="mat-h2">Gebruiksvoorwaarden</p>
        <p style="text-align: justify;">Ut sodales sodales lorem a volutpat. Praesent sodales maximus massa, at
          imperdiet
          elit interdum et. Pellentesque vitae metus suscipit, efficitur ipsum quis, cursus nunc. Aenean ut blandit
          lorem.
          Maecenas iaculis metus vel tortor tristique congue. Cras nec arcu nec sem euismod pharetra vitae ut sapien.
          Suspendisse consequat tellus imperdiet, posuere lorem vel, vehicula risus.</p>
        <br>
      </div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <h1 class="mat-headline" style="word-break: break-word">Open data en privacy</h1>
          <p style="text-align: justify;">
            Nulla eget mauris vehicula nulla sagittis vestibulum ut sed mauris. Pellentesque elementum, urna et tempus
            posuere,
            orci arcu dignissim urna, vel posuere risus ipsum auctor neque. In maximus ante a augue pulvinar lacinia.
            Duis ullamcorper
            quam dui. Mauris faucibus turpis ac leo sagittis, at bibendum ligula interdum. Cras semper pretium urna eu
            porta.
            Aenean vel fringilla neque. Suspendisse facilisis nunc nisl, sit amet sodales neque egestas a. Integer
            suscipit tellus nisl,
            in egestas enim molestie eu. Praesent lobortis faucibus magna, ut pharetra orci elementum vitae.<br>
            <br>
        </div>
      </div>