import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';


@Component({
  selector: 'app-dataplatform-footer-text',
  templateUrl: './dataplatform-footer-text.component.html',
  styleUrls: ['./dataplatform-footer-text.component.scss']
})
export class DataplatformFooterTextComponent implements OnInit, ConfigInterface {

  constructor(
    private navigationService:NavigationService,
    private dynamicInjectorService:DynamicInjectorService
    ) { }

  ngOnInit() {

  }

  getPortal(): string {
    return ID.id;
  }

  public getContactRoute():string{
    return this.navigationService.getContactRoute();
  }

  public getPrivacyRoute():string{
    return this.navigationService.getPrivacyRoute();
  }

  public getFAQRoute():string{
    return this.navigationService.getFAQRoute();
  }

  public getTermsOfUseRoute():string{
    return this.navigationService.getTermsOfUseRoute();
  }

  public getAsset(path) {
    return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${path}`
  }
}
