import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { KeycloakUserService } from '@shared/services/keycloak-user.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakUserServiceResolver implements Resolve<any> {
  constructor(private keycloakUserService: KeycloakUserService) {}

  resolve(): Promise<any> {
    // Perform any asynchronous initialization here
    return this.keycloakUserService.initializeService().then(() => {
      return this.keycloakUserService.isAuthorized(); // Replace with your service method to get data
    });
  }
}