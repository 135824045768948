<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <h2>Presentatie van de data</h2>
    <p style="text-align: justify">De basis van het Dataplatform zijn de datasets die u kunt gebruiken voor eigen
      analyses of toepassingen. Wij
      bieden die data op verschillende manieren aan: als downloadbaar bestand, maar ook via een zogenaamde API. Ons
      dataplatform biedt ook de mogelijkheid om data op verschillende manieren te visualiseren. Het is een extra manier
      om op een makkelijke manier een indruk te krijgen van de inhoud van datasets. In de toekomst zullen deze
      mogelijkheden verder worden uitgebreid zodat u deze visualisaties ook kunt hergebruiken. In de 
      <a href="https://www.docs.civity.nl/docs/developers_Visualisation">documentatie</a> 
      leest u daar meer over. De komende periode zullen
      wij hieronder al een aantal voorbeelden plaatsen hoe datasets op verschillende manieren gepresenteerd kunnen
      worden.</p>
    <p style="text-align: justify">Kaartendenhaag.nl gebruikt onze eigen open data om het gebruik van open data
      eenvoudiger te maken. Met de
      omgevingswet in het vooruitzicht bieden wij een platform waar kaarten en grafieken gemaakt met open data
      aangeboden worden aan burgers, bedrijven, onderzoekers en journalisten.</p>
    <div *ngIf="showLoading" fxLayoutAlign="center center">
      <mat-spinner *ngIf="showLoading"></mat-spinner>
    </div>
    <div fxLayoutAlign="center center">
      <iframe frameborder="0"></iframe>
    </div>
    <br>
  </div>
</div>
